import { render, staticRenderFns } from "./UserPassword.vue?vue&type=template&id=5531b017&scoped=true&"
import script from "./UserPassword.vue?vue&type=script&lang=js&"
export * from "./UserPassword.vue?vue&type=script&lang=js&"
import style0 from "./UserPassword.vue?vue&type=style&index=0&id=5531b017&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5531b017",
  null
  
)

export default component.exports