<template>
  <div class="form-group">
    <label for="password">{{ $t('app.users.password') }}</label>
    <div class="input-group">
      <input type="password" v-validate="'required'" v-feedback="true" class="form-control border-default" @input="$emit('changed', value)" v-model="value" name="password" maxlength="255" />
      <div class="input-group-append">
        <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="$t('tooltip.login_password')">
          <i class="fas fa-key fa-inverse"></i>
        </span>
      </div>
    </div>
    <small>{{ errors.first('password') }}</small>
  </div>
</template>

<script>

export default {
  data: function(){
    return {
      value: ''
    } //END OF RETURN
  } //END OF DATA: FUNCTION
} //END OG EXPORT DEFAULT
</script>
