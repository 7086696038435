<template>

    <div class="row">
      <div :class="(col) ? col : 'col-6'">
        <div class="form-group">
          <label for="password">{{ $t('app.users.password') }}</label>
          <div class="input-group">
            <input type="password" v-feedback="feedback" class="form-control border-default" v-model="password" :name="namedPassword" maxlength="255" data-match="password" :data-prefix="this.prefix"/>
            <div class="input-group-append">
              <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="$t('tooltip.register_password')">
                <i class="fas fa-key fa-inverse"></i>
              </span>
            </div>
          </div>
          <small></small>
        </div>
      </div>
      <div :class="(col) ? col : 'col-6'">
        <div class="form-group">
          <label for="password_confirmation">{{ $t('app.users.confirm_password') }}</label>
          <div class="input-group">
            <input type="password" ref="password_confirmation" v-feedback="feedback" v-model="passwordConfirmation" class="form-control border-default" :name="namedPasswordConfirmation" data-match="confirmation" maxlength="255" :data-prefix="this.prefix"/>
            <div class="input-group-append">
              <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="$t('tooltip.register_confirm_password')">
                <i class="fas fa-key fa-inverse"></i>
              </span>
            </div>
          </div>
          <small></small>
        </div>
      </div>
    </div>

</template>

<script>
import { toUpperCamelCase, isEmpty } from '../../mixins/Utils';
// require('strength-meter-alt');
// require('strength-meter-alt/css/strength-meter-alt.css');
// require('strength-meter-alt/locales/pt-BR.js');
export default {
  data: function(){
    return {
    } //END OF RETURN
  }, //END OF DATA: FUNCTION
  props: [
    'col',
    'disable',
    'name',
    'feedback',
    'validate',
    'prefix'
  ],
  computed: {
    namedPassword(){
      return (this.name)? this.name + '_password' : 'password';
    },
    namedPasswordConfirmation(){
      return (this.name)? this.name + '_password_confirmation' : 'password_confirmation';
    },
    password: {
      get(){
        return this.$store.state[this.prefix].password;
      },
      set(value){
        this.$store.commit(this.prefix + 'Password', value);
      }
    },
    passwordConfirmation: {
      get(){
        return this.$store.state[this.prefix].passwordConfirmation;
      },
      set(value){
        this.$store.commit(this.prefix + 'PasswordConfirmation', value);
      }
    }
  },
  mounted() {
    // $('[name="'+this.namedPassword+'"]').strength({
    //   toggleMask: false, showTooltip: true, language: 'en'
    // });

    // if(this.disable == 'disabled'){
    //   //FORCE THE USERNAME BE DISABLED WHEN IT IS PASSED BY PROPS
    //   document.getElementsByName(this.namedUsername)[0].setAttribute('disabled', this.disabled);
    // }
  }
} //END OG EXPORT DEFAULT
</script>
<style lang="scss" scoped>
input{
  height: 38px;
}
</style>
