export default {
    "en": {
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "delete": "Delete",
            "restore": "Restore",
            "force_delete": "Force Delete",
            "show_trash": "Show Trash",
            "exit_trash": "Exit Trash",
            "gen_random": "Generates a random",
            "new": "Add",
            "edit": "Edit",
            "all": "Select All",
            "none": "Desselect All",
            "actions": "Actions",
            "promote": "Set as manager",
            "demote": "Unset as manager",
            "add": "Add",
            "reset": "Reset",
            "remove": "Remove",
            "view": "View",
            "refresh": "Refresh",
            "today": "Today",
            "yesterday": "Yesterday",
            "month": "Month",
            "last-month": "Last Month",
            "year": "Year",
            "last-year": "Last Year",
            "login": "Login",
            "forgot_password": "Forgot Password",
            "submit_reset": "Reset Password",
            "back_to_login": "Back to Login"
        },
        "installer_messages": {
            "title": "Laravel Installer",
            "next": "Next Step",
            "back": "Previous",
            "finish": "Install",
            "forms": {
                "errorTitle": "The Following errors occurred:"
            },
            "welcome": {
                "templateTitle": "Wizard",
                "title": "Welcome do Installer",
                "message": "Easy Installation and Setup Wizard.",
                "next": "Check Requirements"
            },
            "requirements": {
                "templateTitle": "Step 1 | Server Requirements",
                "title": "Server Requirements",
                "next": "Check Permissions"
            },
            "permissions": {
                "templateTitle": "Step 2 | Permissions",
                "title": "Permissions",
                "next": "Configure Environment"
            },
            "environment": {
                "menu": {
                    "templateTitle": "Step 3 | Environment Settings",
                    "title": "Environment Settings",
                    "desc": "Please select how you want to configure the apps <code>.env</code> file.",
                    "wizard-button": "Form Wizard Setup",
                    "classic-button": "Classic Text Editor"
                },
                "wizard": {
                    "templateTitle": "Step 3 | Environment Settings | Guided Wizard",
                    "title": "Guided <code>.env</code> Wizard",
                    "tabs": {
                        "environment": "Environment",
                        "database": "Database",
                        "application": "Application",
                        "email": "Mail Send"
                    },
                    "form": {
                        "name_required": "An environment name is required.",
                        "app_name_label": "Organization",
                        "app_name_placeholder": "Organization",
                        "app_environment_label": "App Environment",
                        "app_environment_label_local": "Local",
                        "app_environment_label_developement": "Development",
                        "app_environment_label_qa": "Qa",
                        "app_environment_label_production": "Production",
                        "app_environment_label_other": "Other",
                        "app_environment_placeholder_other": "Enter your environment...",
                        "app_debug_label": "App Debug",
                        "app_debug_label_true": "True",
                        "app_debug_label_false": "False",
                        "app_log_level_label": "App Log Level",
                        "app_log_level_label_debug": "debug",
                        "app_log_level_label_info": "info",
                        "app_log_level_label_notice": "notice",
                        "app_log_level_label_warning": "warning",
                        "app_log_level_label_error": "error",
                        "app_log_level_label_critical": "critical",
                        "app_log_level_label_alert": "alert",
                        "app_log_level_label_emergency": "emergency",
                        "app_url_label": "App Url",
                        "app_url_placeholder": "App Url",
                        "db_connection_label": "Database Connection",
                        "db_connection_label_mysql": "mysql",
                        "db_connection_label_sqlite": "sqlite",
                        "db_connection_label_pgsql": "pgsql",
                        "db_connection_label_sqlsrv": "sqlsrv",
                        "db_host_label": "Database Host",
                        "db_host_placeholder": "Database Host",
                        "db_port_label": "Database Port",
                        "db_port_placeholder": "Database Port",
                        "db_name_label": "Database Name",
                        "db_name_placeholder": "Database Name",
                        "db_username_label": "Database User Name",
                        "db_username_placeholder": "Database User Name",
                        "db_password_label": "Database Password",
                        "db_password_placeholder": "Database Password",
                        "app_tabs": {
                            "more_info": "More Info",
                            "broadcasting_title": "Broadcasting, Caching, Session, &amp; Queue",
                            "broadcasting_label": "Broadcast Driver",
                            "broadcasting_placeholder": "Broadcast Driver",
                            "cache_label": "Cache Driver",
                            "cache_placeholder": "Cache Driver",
                            "session_label": "Session Driver",
                            "session_placeholder": "Session Driver",
                            "queue_label": "Queue Driver",
                            "queue_placeholder": "Queue Driver",
                            "redis_label": "Redis Driver",
                            "redis_host": "Redis Host",
                            "redis_password": "Redis Password",
                            "redis_port": "Redis Port",
                            "mail_label": "Mail",
                            "mail_driver_label": "Mail Driver",
                            "mail_driver_placeholder": "Mail Driver",
                            "mail_host_label": "Mail Host",
                            "mail_host_placeholder": "Mail Host",
                            "mail_port_label": "Mail Port",
                            "mail_port_placeholder": "Mail Port",
                            "mail_username_label": "Mail Username",
                            "mail_username_placeholder": "Mail Username",
                            "mail_password_label": "Mail Password",
                            "mail_password_placeholder": "Mail Password",
                            "mail_encryption_label": "Mail Encryption",
                            "mail_encryption_placeholder": "Mail Encryption",
                            "mail_encryption_null": "Without Encryption",
                            "pusher_label": "Pusher",
                            "pusher_app_id_label": "Pusher App Id",
                            "pusher_app_id_palceholder": "Pusher App Id",
                            "pusher_app_key_label": "Pusher App Key",
                            "pusher_app_key_palceholder": "Pusher App Key",
                            "pusher_app_secret_label": "Pusher App Secret",
                            "pusher_app_secret_palceholder": "Pusher App Secret"
                        },
                        "buttons": {
                            "setup_database": "Setup Database",
                            "setup_application": "Setup Application",
                            "setup_email": "Setup Mail Send",
                            "install": "Install"
                        }
                    }
                },
                "classic": {
                    "templateTitle": "Step 3 | Environment Settings | Classic Editor",
                    "title": "Classic Environment Editor",
                    "save": "Save .env",
                    "back": "Use Form Wizard",
                    "install": "Save and Install"
                },
                "success": "Your .env file settings have been saved.",
                "errors": "Unable to save the .env file, Please create it manually."
            },
            "install": "Install",
            "installed": {
                "success_log_message": "Laravel Installer successfully INSTALLED on "
            },
            "final": {
                "title": "Installation Finished",
                "templateTitle": "Installation Finished",
                "finished": "Application has been successfully installed.",
                "migration": "Migration & Seed Console Output:",
                "console": "Application Console Output:",
                "log": "Installation Log Entry:",
                "env": "Final .env File:",
                "exit": "Click here to exit"
            },
            "updater": {
                "title": "Updater",
                "welcome": {
                    "title": "Welcome To The Updater",
                    "message": "Welcome to the update wizard."
                },
                "overview": {
                    "title": "Overview",
                    "message": "There is 1 update.|There are {number} updates.",
                    "install_updates": "Install Updates"
                },
                "final": {
                    "title": "Finished",
                    "finished": "Application's has been successfully updated.",
                    "exit": "Click here to exit"
                },
                "log": {
                    "success_message": "Laravel Installer successfully UPDATED on "
                }
            }
        },
        "app": {
            "common": {
                "general": "General",
                "success": "Success",
                "default": "Default",
                "warnings": {
                    "sure": "Are you sure?",
                    "changes": "All changes will be lost",
                    "delete": "This registry will be deleted",
                    "restore": "This registry will be restored",
                    "force_delete": "This registry will be permanently deleted",
                    "password": "Type you password to complete this operation"
                },
                "errors": {
                    "validation": "Validation Error",
                    "fatal": "Fatal Error",
                    "password": "Wrong Password!",
                    "unexpected": "An unexpected error occurred, please try again",
                    "authorization": "You don't have authorization to make this operation",
                    "whoops": "Whoops!",
                    "someproblems": "There were some problems with your input.",
                    "check_all_fields": "Check that all mandatory fields are completed",
                    "same_password": "New password cannot be the same as the old password",
                    "min_version": "Update from this version is not supported, please update to {version} version first",
                    "mono_entities": "Sorry, the multi-entity mode is not yet ready for use in production, the registration of an entity is allowed."
                },
                "yes": "Yes",
                "no": "No",
                "active": "Active",
                "inactive": "Inactive",
                "date_begin": "Begin Date",
                "date_end": "End Date",
                "id": "Id",
                "personalization": "Personalization",
                "database": "Database",
                "administration": "Administration",
                "maintenance_mode": "Maintenance Mode",
                "maintenance": "We're currently working on the application, please try again later!"
            },
            "entities": {
                "model": "Entity|Entities",
                "name": "Entity Name",
                "parent": "Parent Entity",
                "user": "Member",
                "errors": {
                    "managers": "The member of this entity must be manager|2 members of this entity must be manager",
                    "is_parent": "The entity <b>{entity}</b> is a parent entity, please correct this before try again",
                    "has_members": "The entity <b>{entity}</b> has members associated, please correct this before try again"
                }
            },
            "users": {
                "model": "User|Users",
                "name": "Name",
                "username": "Username",
                "password": "Password",
                "confirm_password": "Password Confirmation",
                "given_name": "Given Name",
                "surname": "Surname",
                "email": "E-mail",
                "authentication": "Authentication Data",
                "auth_source": "Authentication Source",
                "errors": {
                    "is_manager": "The user <b>{user}</b> is manager of entity <b>{entity}</b>, please correct this before try again",
                    "logged": "The user <b>{user}</b> is the current logged user, can't continue the operation",
                    "administrator": "The user <b>{user}</b> is the only system administrator, please correct this before try again"
                },
                "reload": "The application will be reloaded to apply the alterations"
            },
            "proxy_servers": {
                "model": "Proxy Server|Proxy Servers",
                "name": "Proxy Server Name",
                "description": "Proxy Server Description",
                "key": "Proxy Server Key",
                "local": "Local Server",
                "errors": {
                    "in_use": "The server <b>{server}</b> is in use, please correct this before try again"
                }
            },
            "quota_period_graces": {
                "model": "Period Grace|Period Graces",
                "name": "Period Grace Name",
                "description": "Period Grace Description",
                "grace_period": "Grace Period (months)",
                "errors": {
                    "in_use": "The grace <b>{grace}</b> is in use, please correct this before try again"
                }
            },
            "printers": {
                "model": "Printer|Printers",
                "name": "Printer Name",
                "description": "Printer Description",
                "pool_name": "CUPS Printer Pool",
                "status": "Printer Status",
                "address": "IP Address",
                "snmp_enabled": "SNMP Enabled",
                "snmp_community": "SNMP Community String",
                "snmp_version": "SNMP Version",
                "pagecounter": "Printer Page Counter",
                "copycounter": "Printer Copy Counter",
                "is_mfp": "Multi-function Printer",
                "individual_quota": "Special quota",
                "proxy_id": "Proxy Server",
                "network": "Network",
                "version": "Version {version}",
                "errors": {
                    "in_use": "The printer <b>{printer}</b> is in use, please correct this before try again",
                    "snmp": "SNMP Unreachable or Incorrect SNMP Credentials"
                }
            },
            "print_profiles": {
                "model": "Print Profile|Print Profiles",
                "name": "Print Profile Name",
                "grace_id": "Print Profile Grace Period",
                "default_value": "Quota Default Value",
                "last_reset": "Last Reseted At",
                "next_reset": "Next Reset In",
                "only_accounting": "Only Accounting Profile",
                "errors": {
                    "in_use": "The profile <b>{profile}</b> is in use, please correct this before try again",
                    "has_other": "This printer needs profile exclusivity, please remove all the printers first",
                    "exclusivity": "The printer already added needs profile exclusivity, please remove the printer first!"
                }
            },
            "locales": {
                "model": "Locale|Locales",
                "iso_2_letter_code": "Country Code",
                "localized_name": "Localized Country Name",
                "zip_locale": "Locale Package",
                "errors": {
                    "invalid": "The locale package is invalid",
                    "default": "The locale <b>{locale}</b> is the system default, please correct this before try again",
                    "in_use": "The locale <b>{locale}</b> is in use, please correct this before try again"
                }
            },
            "ldap_servers": {
                "model": "LDAP Server|LDAP Servers",
                "name": "LDAP Server Name",
                "host": "Server Address",
                "port": "Server Port",
                "base_dn": "Base DN",
                "search_dn": "Search DN",
                "root_dn": "Root DN",
                "root_password": "Root Password",
                "ldap_version": "LDAP Version",
                "login_attribute": "Login Attribute",
                "local": "Local Authentication",
                "errors": {
                    "in_use": "The server <b>{server}</b> is in use, please correct this before try again",
                    "default": "The server <b>{server}</b> is the system default, please correct this before try again",
                    "unreachable": "LDAP Server Unreachable"
                }
            },
            "print_jobs": {
                "model": "Print Job|Print Jobs",
                "date": "Job Date",
                "pages": "Job Pages",
                "title": "Job Title",
                "filter": "Filter Print Jobs",
                "printed_pages": "Printed Pages"
            },
            "quotas": {
                "quota_usage": "Quota usage",
                "history": "Usage History"
            },
            "solicitations": {
                "model": "Solicitation|Solicitations",
                "pending": "Pending Solicitations",
                "analysis": "Analyze Solicitation",
                "filter": "Filter Solicitations",
                "date": "Solicitation Date",
                "value": "Solicited Value",
                "status": "Solicitation Status",
                "my": "My Solicitations",
                "justificative": "Requester Justificative",
                "value_approved": "Approved Value",
                "justificative_analyst": "Analyst Justificative",
                "analyzed_by": "Analyzed By",
                "errors": {
                    "analyzed": "This solicitation is already analyzed, this operation cannot be done",
                    "pending": "There is already a pending solicitation, this operation cannot be done"
                },
                "in_analysis": "Waiting analysis",
                "approved": "Approved",
                "partial_approved": "Partially Approved",
                "denied": "Denied",
                "unknown": "Unknown"
            },
            "roles": {
                "model": "Role|Roles",
                "administrator": "Administrator",
                "user": "User"
            },
            "permissions": {
                "model": "Permission|Permissions",
                "admin": "Administration",
                "reports": "Reports",
                "create": "Create",
                "update": "Update",
                "read": "Read",
                "delete": "Delete",
                "viewall": "View All Print Jobs"
            },
            "languages": {
                "model": "Language|Languages"
            },
            "themes": {
                "model": "Theme|Themes"
            },
            "login": {
                "login": "Sign in",
                "remember_me": "Remember Me",
                "welcome": "Sign in to start your session"
            },
            "parameters": {
                "model": "Setting|Settings",
                "core": "General",
                "app_email": "Application Email",
                "app_url": "Application URL",
                "app_timezone": "Application Timezone",
                "app_auth": "Default Authentication Source",
                "forgot_anchor": "Forgot Password Anchor",
                "graph_interval": "Usage Chart Interval (months)",
                "self_approval": "User can self approve solicitations",
                "default_language": "Default Language",
                "default_theme": "Default Theme",
                "app_date_format": "Application Date Format",
                "admin_ips": "Administration IP Addresses",
                "ip_address": "IP Address",
                "errors": {
                    "ip_invalid": "The value must be a valid IP address."
                }
            },
            "install": {
                "default_account": "Log in using the default Administrator account.<br>User Name: <b>simpres</b><br>Password: <b>simpres</b>",
                "database_unreachable": "Database Unreachable"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                },
                "selected_rows": {
                    "required": "At least one row must be selected",
                    "array": "At least one row must be selected"
                },
                "operation": {
                    "required": "Invalid operation"
                },
                "domain_or_ip": "The {attribute} must be a domain name or a ip address"
            },
            "attributes": {
                "created_at": "Created",
                "updated_at": "Updated",
                "deleted_at": "Deleted",
                "operation": "Operation",
                "id": "row"
            }
        },
        "passwords": {
            "password": "Passwords must be at least eight characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "sidebar": {
            "home": "Home",
            "admin": "Administration",
            "reports": "Reports",
            "config": "Configuration",
            "list": "Lists"
        },
        "email": {
            "password": {
                "title": "Reset Password",
                "message": "You are receiving this email because we received a password reset request for your account.",
                "expires": "This password reset link will expire in {count} minutes.",
                "ignore": "If you did not request a password reset, no further action is required."
            },
            "whoops": "Whoops!",
            "hello": "Hello!",
            "regards": "Regards",
            "troubles": "If you’re having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser: [{displayableActionUrl}]({actionURL})"
        },
        "datatables": {
            "select": {
                "rows_0": "Click a row to select it",
                "rows_1": "Only 1 row selected",
                "rows_n": "You have selected %d rows"
            }
        }
    },
    "pt-BR": {
        "buttons": {
            "cancel": "Cancelar",
            "save": "Salvar",
            "delete": "Excluir",
            "restore": "Restaurar",
            "force_delete": "Forçar exclusão",
            "show_trash": "Lixeira",
            "exit_trash": "Sair da lixeira",
            "gen_random": "Gerar aleatória",
            "new": "Adicionar",
            "edit": "Editar",
            "all": "Selecionar tudo",
            "none": "Desmarcar todos",
            "actions": "Ações",
            "promote": "Definir como gerente",
            "demote": "Remover como gerente",
            "add": "Adicionar",
            "reset": "Redefinir",
            "remove": "Retirar",
            "view": "Visualizar",
            "refresh": "Atualizar",
            "today": "Hoje",
            "yesterday": "Ontem",
            "month": "Mês",
            "last-month": "Mês passado",
            "year": "Ano",
            "last-year": "Ano passado",
            "login": "Iniciar sessão",
            "forgot_password": "Esqueci a senha",
            "submit_reset": "Redefinir senha",
            "back_to_login": "Volte ao login"
        },
        "installer_messages": {
            "title": "Instalador Laravel",
            "next": "Próximo Passo",
            "finish": "Instalar",
            "welcome": {
                "templateTitle": "Assistente",
                "title": "Bem-vindo ao Assistente",
                "message": "Assistente de instalação e configuração.",
                "next": "Checar Requisitos"
            },
            "requirements": {
                "templateTitle": "Passo 1 | Requisitos do Servidor",
                "title": "Requisitos do Servidor",
                "next": "Chaecar Permissões"
            },
            "permissions": {
                "templateTitle": "Passo 2 | Permissões",
                "title": "Permissões",
                "next": "Configurar o Ambiente"
            },
            "environment": {
                "menu": {
                    "templateTitle": "Passo 3 | Configurações de Ambiente",
                    "title": "Configurações de Ambiente"
                },
                "wizard": {
                    "templateTitle": "Passo 3 | Configurações de Ambiente",
                    "title": "Configurações de Ambiente",
                    "tabs": {
                        "environment": "Ambiente",
                        "database": "Banco de Dados",
                        "application": "Aplicação",
                        "email": "Envio de Emails"
                    },
                    "form": {
                        "name_required": "An environment name is required.",
                        "app_name_label": "Organização",
                        "app_name_placeholder": "Organização",
                        "app_environment_label": "App Environment",
                        "app_environment_label_local": "Local",
                        "app_environment_label_developement": "Development",
                        "app_environment_label_qa": "Qa",
                        "app_environment_label_production": "Production",
                        "app_environment_label_other": "Other",
                        "app_environment_placeholder_other": "Enter your environment...",
                        "app_debug_label": "App Debug",
                        "app_debug_label_true": "True",
                        "app_debug_label_false": "False",
                        "app_log_level_label": "App Log Level",
                        "app_log_level_label_debug": "debug",
                        "app_log_level_label_info": "info",
                        "app_log_level_label_notice": "notice",
                        "app_log_level_label_warning": "warning",
                        "app_log_level_label_error": "error",
                        "app_log_level_label_critical": "critical",
                        "app_log_level_label_alert": "alert",
                        "app_log_level_label_emergency": "emergency",
                        "app_url_label": "Url da Aplicação",
                        "app_url_placeholder": "Url da Aplicação",
                        "db_connection_label": "Database Connection",
                        "db_connection_label_mysql": "mysql",
                        "db_connection_label_sqlite": "sqlite",
                        "db_connection_label_pgsql": "pgsql",
                        "db_connection_label_sqlsrv": "sqlsrv",
                        "db_host_label": "Endereço do Banco de Dados",
                        "db_host_placeholder": "Endereço do Banco de Dados",
                        "db_port_label": "Porta do Banco de Dados",
                        "db_port_placeholder": "Porta do Banco de Dados",
                        "db_name_label": "Nome do Banco de Dados",
                        "db_name_placeholder": "Nome do Banco de Dados",
                        "db_username_label": "Usuário do Banco de Dados",
                        "db_username_placeholder": "Usuário do Banco de Dados",
                        "db_password_label": "Senha do Banco de Dados",
                        "db_password_placeholder": "Senha do Banco de Dados",
                        "app_tabs": {
                            "more_info": "More Info",
                            "broadcasting_title": "Broadcasting, Caching, Session, &amp; Queue",
                            "broadcasting_label": "Broadcast Driver",
                            "broadcasting_placeholder": "Broadcast Driver",
                            "cache_label": "Cache Driver",
                            "cache_placeholder": "Cache Driver",
                            "session_label": "Session Driver",
                            "session_placeholder": "Session Driver",
                            "queue_label": "Queue Driver",
                            "queue_placeholder": "Queue Driver",
                            "redis_label": "Redis Driver",
                            "redis_host": "Redis Host",
                            "redis_password": "Redis Password",
                            "redis_port": "Redis Port",
                            "mail_label": "Mail",
                            "mail_driver_label": "Método de Envio",
                            "mail_driver_placeholder": "Método de Envio",
                            "mail_host_label": "Endereço do Servidor",
                            "mail_host_placeholder": "Endereço do Servidor",
                            "mail_port_label": "Porta",
                            "mail_port_placeholder": "Porta",
                            "mail_username_label": "Usuário",
                            "mail_username_placeholder": "Usuário",
                            "mail_password_label": "Senha",
                            "mail_password_placeholder": "Senha",
                            "mail_encryption_label": "Criptografia",
                            "mail_encryption_placeholder": "Criptografia",
                            "mail_encryption_null": "Sem Criptografia",
                            "pusher_label": "Pusher",
                            "pusher_app_id_label": "Pusher App Id",
                            "pusher_app_id_palceholder": "Pusher App Id",
                            "pusher_app_key_label": "Pusher App Key",
                            "pusher_app_key_palceholder": "Pusher App Key",
                            "pusher_app_secret_label": "Pusher App Secret",
                            "pusher_app_secret_palceholder": "Pusher App Secret"
                        },
                        "buttons": {
                            "setup_database": "Configurar Banco de Dados",
                            "setup_application": "Configurar Aplicação",
                            "setup_email": "Configurar Envio de Emails",
                            "install": "Instalar"
                        }
                    }
                },
                "title": "Configurações de Ambiente",
                "save": "Salvar .env",
                "success": "Suas configurações de arquivo .env foram salvas.",
                "errors": "Não foi possível salvar o arquivo .env, por favor crie-o manualmente."
            },
            "final": {
                "title": "Terminado",
                "finished": "Aplicação foi instalada com sucesso",
                "exit": "Clique aqui para sair"
            },
            "updater": {
                "title": "Atualizador",
                "welcome": {
                    "title": "Bem-vindo ao Atualizador",
                    "message": "Bem-vindo ao assitente de atualização."
                },
                "overview": {
                    "title": "Visão Geral",
                    "message": "Há uma atualização.|Existem {number} atualizações.",
                    "install_updates": "Atualizar"
                },
                "final": {
                    "title": "Terminado",
                    "finished": "Aplicação foi atualizada com sucesso",
                    "exit": "Clique aqui para sair"
                },
                "log": {
                    "success_message": "Laravel Installer successfully UPDATED on "
                }
            }
        },
        "app": {
            "common": {
                "general": "Geral",
                "success": "Sucesso",
                "default": "padrão",
                "warnings": {
                    "sure": "Você tem certeza?",
                    "changes": "Todas as alterações serão perdidas",
                    "delete": "Esses registros serão apagados",
                    "restore": "Esses registros serão restaurados",
                    "force_delete": "Esses registros serão apagados permanentemente",
                    "password": "Digite sua senha para confirmar a operação"
                },
                "errors": {
                    "validation": "Erro de Validação",
                    "fatal": "Erro Fatal",
                    "password": "Senha Incorreta",
                    "unexpected": "Ocorreu um erro inesperado, tente novamente",
                    "authorization": "Você não tem permissão para realizar essa operação",
                    "whoops": "Ops!",
                    "someproblems": "Houve alguns problemas com os dados informados.",
                    "check_all_fields": "Confira se todos os campos obrigatórios foram preenchidos",
                    "same_password": "A nova senha não pode ser igual à senha antiga",
                    "min_version": "Atualização a partir desta versão não é suportada, por favor atualize para a versão {version} primeiro",
                    "mono_entities": "Perdão, o modo-multientidades não está pronto ainda para uso em produção, é permitido o cadastro de uma entidade."
                },
                "yes": "Sim",
                "no": "Não",
                "active": "Ativo",
                "inactive": "Inativo",
                "date_begin": "Data Inicial",
                "date_end": "Data Final",
                "id": "Id",
                "personalization": "Personalização",
                "database": "Banco de Dados",
                "administration": "Administração",
                "maintenance_mode": "Modo de Manutenção",
                "maintenance": "No momento, estamos trabalhando na aplicação, tente novamente mais tarde!"
            },
            "entities": {
                "model": "Entidade|Entidades",
                "name": "Nome da Entidade",
                "parent": "Entidade Pai",
                "user": "Membro",
                "errors": {
                    "managers": "O membro desta entidade deve ser gerente|2 membros desta entidade devem ser gerentes",
                    "is_parent": "A entidade <b>{entity}</b> é uma entidade pai, corrija isso antes de tentar novamente",
                    "has_members": "A entidade <b>{entity}</b> tem membros associados, corrija isso antes de tentar novamente"
                }
            },
            "users": {
                "model": "Usuário|Usuários",
                "name": "Nome",
                "username": "Nome de usuário",
                "password": "Senha",
                "confirm_password": "Confirmação de Senha",
                "given_name": "Nome",
                "surname": "Sobrenome",
                "email": "E-mail",
                "authentication": "Dados de autenticação",
                "auth_source": "Fonte de autenticação",
                "errors": {
                    "is_manager": "O usuário <b>{user}</b> é gerente da entidade <b>{entity}</b>, corrija isso antes de tentar novamente",
                    "logged": "O usuário <b>{user}</b> é o usuaŕio atualmente logado, não é possível continuar a operação",
                    "administrator": "O usuário <b>{user}</b> é o único administrador do sistema, corrija isso antes de tentar novamente"
                },
                "reload": "A aplicação será recarregada para aplicar as alterações"
            },
            "proxy_servers": {
                "model": "Servidor Proxy|Servidores Proxy",
                "name": "Nome do Servidor",
                "description": "Descrição do Servidor",
                "key": "Chave do Servidor",
                "local": "Servidor Local",
                "errors": {
                    "in_use": "O servidor <b>{server}</b> está em uso, corrija isso antes de tentar novamente"
                }
            },
            "quota_period_graces": {
                "model": "Período de Validade|Períodos de Validade",
                "name": "Nome do Período de Validade",
                "description": "Descrição do Período de Validade",
                "grace_period": "Período de Validade (meses)",
                "errors": {
                    "in_use": "O período de validade <b>{grace}</b> está em uso, corrija isso antes de tentar novamente"
                }
            },
            "printers": {
                "model": "Impressora|Impressoras",
                "name": "Nome da Impressora",
                "description": "Descrição da Impressora",
                "pool_name": "Fila de Impressão no CUPS",
                "status": "Status da Impressora",
                "address": "Endereço IP",
                "snmp_enabled": "SNMP Habilitado",
                "snmp_community": "String de Comunidade SNMP",
                "snmp_version": "Versão do SNMP",
                "pagecounter": "Contator de páginas da impressora",
                "copycounter": "Contador de cópias da impressora",
                "is_mfp": "Impressora Multifuncional",
                "individual_quota": "Cota Especial",
                "proxy_id": "Servidor",
                "network": "Rede",
                "version": "Versão {version}",
                "errors": {
                    "in_use": "A impressora <b>{printer}</b> está em uso, corrija isso antes de tentar novamente",
                    "snmp": "SNMP inacessível ou credenciais SNMP incorretas"
                }
            },
            "print_profiles": {
                "model": "Perfil de Impressão|Perfis de Impressão",
                "name": "Nome do Perfil de Impressão",
                "grace_id": "Período de Validade do Perfil de Impressão",
                "default_value": "Cota Padrão",
                "last_reset": "Última Redefinição Em",
                "next_reset": "Próxima Redefinição Em",
                "only_accounting": "Apenas contabilização",
                "errors": {
                    "in_use": "O perfil <b>{profile}</b> está em uso, corrija isso antes de tentar novamente",
                    "has_other": "Esta impressora precisa de exclusividade de perfil, remova todas as impressoras primeiro",
                    "exclusivity": "A impressora já adicionada precisa de exclusividade de perfil; remova-a primeiro!"
                }
            },
            "locales": {
                "model": "Localização|Localizações",
                "iso_2_letter_code": "Código do País",
                "localized_name": "Nome do Páis Localizado",
                "zip_locale": "Pacote de Localização",
                "errors": {
                    "invalid": "P pacote de localização é invlálido",
                    "default": "A localização <b>{locale}</b> é o padrão do sistema, corrija isso antes de tentar novamente",
                    "in_use": "A localização <b>{locale}</b> está em uso, corrija isso antes de tentar novamente"
                }
            },
            "ldap_servers": {
                "model": "Servidor LDAP|Servidores LDAP",
                "name": "Nome do Servidor LDAP",
                "host": "Endereço do Servidor",
                "port": "Porta do Servidor",
                "base_dn": "Base DN",
                "search_dn": "DN de Busca",
                "root_dn": "DN do Root",
                "root_password": "Senha do Root",
                "ldap_version": "Versão do LDAP",
                "login_attribute": "Login Attribute",
                "local": "Autenticação Local",
                "errors": {
                    "in_use": "O servidor <b>{server}</b> está em uso, corrija isso antes de tentar novamente",
                    "default": "O servidor <b>{server}</b> é o padrão do sistema, corrija isso antes de tentar novamente",
                    "unreachable": "Servidor LDAP Inacessível"
                }
            },
            "print_jobs": {
                "model": "Trabalho de Impressão|Trabalhos de Impressão",
                "date": "Data do trabalho",
                "pages": "Páginas do trabalho",
                "title": "Título do trabalho",
                "filter": "Filtar trabalhos",
                "printed_pages": "Páginas Impressas"
            },
            "quotas": {
                "quota_usage": "Uso da Cota",
                "history": "Histórico de Uso"
            },
            "solicitations": {
                "model": "Solicitação|Solicitações",
                "pending": "Solicitações pendentes",
                "analysis": "Analisar solicitação",
                "filter": "Filtar solicitações",
                "date": "Data da solicitação",
                "value": "Valor solicitado",
                "status": "Situação da solicitação",
                "my": "Minhas Solicitações",
                "justificative": "Justificativa do requerente",
                "value_approved": "Valor aprovado",
                "justificative_analyst": "Justificativa do analista",
                "analyzed_by": "Analisado por",
                "errors": {
                    "analyzed": "Esta solicitação já está analisada, esta operação não pode ser realizada",
                    "pending": "Já existe uma solicitação pendente, esta operação não pode ser realizada"
                },
                "in_analysis": "Aguardando Análise",
                "approved": "Aprovada",
                "partial_approved": "Aprovada Parcialmente",
                "denied": "Negada",
                "unknown": "Desconhecida"
            },
            "roles": {
                "model": "Perfil|Perfis",
                "administrator": "Administrador",
                "user": "Usuário"
            },
            "permissions": {
                "model": "Permissão|Permissões",
                "admin": "Administração",
                "reports": "Relatórios",
                "create": "Criar",
                "update": "Atualizar",
                "read": "Ler",
                "delete": "Apagar",
                "viewall": "Visualizar trabalhos de impressão"
            },
            "languages": {
                "model": "Idioma|Idiomas"
            },
            "themes": {
                "model": "Tema|Temas"
            },
            "login": {
                "login": "Iniciar Sessão",
                "remember_me": "Mantenha conectado",
                "welcome": "Faça login para iniciar sua sessão"
            },
            "parameters": {
                "model": "Configuração|Configurações",
                "core": "Geral",
                "app_email": "E-mail da Aplicação",
                "app_url": "URL da Aplicação",
                "app_timezone": "Fuso Horário da Aplicação",
                "app_auth": "Fonte de Autenticação Padrão",
                "forgot_anchor": "Atributo de Recuperação de Senha",
                "graph_interval": "Período para gráfico de uso (meses)",
                "self_approval": "Usuário pode Aprovar suas Solicitações",
                "default_language": "Idioma Padrão",
                "default_theme": "Tema Padrão",
                "app_date_format": "Formato de Data da Aplicação",
                "admin_ips": "Endereços IPS para Administração",
                "ip_address": "Endereço IP",
                "errors": {
                    "ip_invalid": "O valor deve ser um endereço IP válido."
                }
            },
            "install": {
                "default_account": "Faça login usando a conta de administrador padrão:<br>Nome do usuário: <b>simpres</b><br>Senha: <b>simpres</b>",
                "database_unreachable": "Banco de Dados Inacessível"
            }
        },
        "pagination": {
            "previous": "&laquo; Anterior",
            "next": "Próximo &raquo;"
        },
        "validation": {
            "accepted": "O campo {attribute} deve ser aceito.",
            "active_url": "O campo {attribute} deve conter uma URL válida.",
            "after": "O campo {attribute} deve conter uma data posterior a {date}.",
            "after_or_equal": "O campo {attribute} deve conter uma data superior ou igual a {date}.",
            "alpha": "O campo {attribute} deve conter apenas letras.",
            "alpha_dash": "O campo {attribute} deve conter apenas letras, números e traços.",
            "alpha_num": "O campo {attribute} deve conter apenas letras e números .",
            "array": "O campo {attribute} deve conter um array.",
            "before": "O campo {attribute} deve conter uma data anterior a {date}.",
            "before_or_equal": "O campo {attribute} deve conter uma data inferior ou igual a {date}.",
            "between": {
                "numeric": "O campo {attribute} deve conter um número entre {min} e {max}.",
                "file": "O campo {attribute} deve conter um arquivo de {min} a {max} kilobytes.",
                "string": "O campo {attribute} deve conter entre {min} a {max} caracteres.",
                "array": "O campo {attribute} deve conter de {min} a {max} itens."
            },
            "boolean": "O campo {attribute} deve conter o valor verdadeiro ou falso.",
            "confirmed": "A confirmação para o campo {attribute} não coincide.",
            "date": "O campo {attribute} não contém uma data válida.",
            "date_equals": "O campo {attribute} deve ser uma data igual a {date}.",
            "date_format": "A data informada para o campo {attribute} não respeita o formato {format}.",
            "different": "Os campos {attribute} e {other} devem conter valores diferentes.",
            "digits": "O campo {attribute} deve conter {digits} dígitos.",
            "digits_between": "O campo {attribute} deve conter entre {min} a {max} dígitos.",
            "dimensions": "O valor informado para o campo {attribute} não é uma dimensão de imagem válida.",
            "distinct": "O campo {attribute} contém um valor duplicado.",
            "email": "O campo {attribute} não contém um endereço de email válido.",
            "ends_with": "O campo {attribute} deve terminar com um dos seguintes valores: {values}",
            "exists": "O valor selecionado para o campo {attribute} é inválido.",
            "file": "O campo {attribute} deve conter um arquivo.",
            "filled": "O campo {attribute} é obrigatório.",
            "gt": {
                "numeric": "O campo {attribute} deve ser maior que {value}.",
                "file": "O arquivo {attribute} deve ser maior que {value} kilobytes.",
                "string": "O campo {attribute} deve ser maior que {value} caracteres.",
                "array": "O campo {attribute} deve ter mais que {value} itens."
            },
            "gte": {
                "numeric": "O campo {attribute} deve ser maior ou igual a {value}.",
                "file": "O arquivo {attribute} deve ser maior ou igual a {value} kilobytes.",
                "string": "O campo {attribute} deve ser maior ou igual a {value} caracteres.",
                "array": "O campo {attribute} deve ter {value} itens ou mais."
            },
            "image": "O campo {attribute} deve conter uma imagem.",
            "in": "O campo {attribute} não contém um valor válido.",
            "in_array": "O campo {attribute} não existe em {other}.",
            "integer": "O campo {attribute} deve conter um número inteiro.",
            "ip": "O campo {attribute} deve conter um IP válido.",
            "ipv4": "O campo {attribute} deve conter um IPv4 válido.",
            "ipv6": "O campo {attribute} deve conter um IPv6 válido.",
            "json": "O campo {attribute} deve conter uma string JSON válida.",
            "lt": {
                "numeric": "O campo {attribute} deve ser menor que {value}.",
                "file": "O arquivo {attribute} ser menor que {value} kilobytes.",
                "string": "O campo {attribute} deve ser menor que {value} caracteres.",
                "array": "O campo {attribute} deve ter menos que {value} itens."
            },
            "lte": {
                "numeric": "O campo {attribute} deve ser menor ou igual a {value}.",
                "file": "O arquivo {attribute} ser menor ou igual a {value} kilobytes.",
                "string": "O campo {attribute} deve ser menor ou igual a {value} caracteres.",
                "array": "O campo {attribute} não deve ter mais que {value} itens."
            },
            "max": {
                "numeric": "O campo {attribute} não pode conter um valor superior a {max}.",
                "file": "O campo {attribute} não pode conter um arquivo com mais de {max} kilobytes.",
                "string": "O campo {attribute} não pode conter mais de {max} caracteres.",
                "array": "O campo {attribute} deve conter no máximo {max} itens."
            },
            "mimes": "O campo {attribute} deve conter um arquivo do tipo: {values}.",
            "mimetypes": "O campo {attribute} deve conter um arquivo do tipo: {values}.",
            "min": {
                "numeric": "O campo {attribute} deve conter um número superior ou igual a {min}.",
                "file": "O campo {attribute} deve conter um arquivo com no mínimo {min} kilobytes.",
                "string": "O campo {attribute} deve conter no mínimo {min} caracteres.",
                "array": "O campo {attribute} deve conter no mínimo {min} itens."
            },
            "not_in": "O campo {attribute} contém um valor inválido.",
            "not_regex": "O formato do valor {attribute} é inválido.",
            "numeric": "O campo {attribute} deve conter um valor numérico.",
            "password": "A senha está incorreta.",
            "present": "O campo {attribute} deve estar presente.",
            "regex": "O formato do valor informado no campo {attribute} é inválido.",
            "required": "O campo {attribute} é obrigatório.",
            "required_if": "O campo {attribute} é obrigatório quando o valor do campo {other} é igual a {value}.",
            "required_unless": "O campo {attribute} é obrigatório a menos que {other} esteja presente em {values}.",
            "required_with": "O campo {attribute} é obrigatório quando {values} está presente.",
            "required_with_all": "O campo {attribute} é obrigatório quando um dos {values} está presente.",
            "required_without": "O campo {attribute} é obrigatório quando {values} não está presente.",
            "required_without_all": "O campo {attribute} é obrigatório quando nenhum dos {values} está presente.",
            "same": "Os campos {attribute} e {other} devem conter valores iguais.",
            "size": {
                "numeric": "O campo {attribute} deve conter o número {size}.",
                "file": "O campo {attribute} deve conter um arquivo com o tamanho de {size} kilobytes.",
                "string": "O campo {attribute} deve conter {size} caracteres.",
                "array": "O campo {attribute} deve conter {size} itens."
            },
            "starts_with": "O campo {attribute} deve começar com um dos seguintes valores: {values}",
            "string": "O campo {attribute} deve ser uma string.",
            "timezone": "O campo {attribute} deve conter um fuso horário válido.",
            "unique": "O valor informado para o campo {attribute} já está em uso.",
            "uploaded": "Falha no Upload do arquivo {attribute}.",
            "url": "O formato da URL informada para o campo {attribute} é inválido.",
            "uuid": "O campo {attribute} deve ser um UUID válido.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                },
                "selected_rows": {
                    "required": "Pelo menos um registro deve ser selecionado",
                    "array": "Pelo menos um registro deve ser selecionado"
                },
                "operation": {
                    "required": "Operação Inválida"
                },
                "domain_or_ip": "O campo {attribute} deve ser um nome de domínio ou endereço ip"
            },
            "attributes": {
                "created_at": "Criado",
                "updated_at": "Atualizado",
                "deleted_at": "Apagado",
                "operation": "Operação",
                "id": "Registro"
            }
        },
        "passwords": {
            "password": "A senha e a confirmação devem combinar e possuir pelo menos seis caracteres.",
            "reset": "Sua senha foi redefinida!",
            "sent": "Enviamos seu link de redefinição de senha por e-mail!",
            "token": "Este token de redefinição de senha é inválido.",
            "user": "Não encontramos um usuário com esse endereço de e-mail."
        },
        "auth": {
            "failed": "Credenciais informadas não correspondem com nossos registros.",
            "throttle": "Você realizou muitas tentativas de login. Por favor, tente novamente em {seconds} segundos."
        },
        "sidebar": {
            "home": "Início",
            "admin": "Administração",
            "reports": "Relatórios",
            "config": "Configuração",
            "list": "Listas"
        },
        "email": {
            "password": {
                "title": "Recuperação de Senha",
                "message": "Você está recebendo este e-mail porque recebemos uma solicitação de redefinição de senha para sua conta.",
                "expires": "Este link de redefinição de senha expirará em {count} minutos.",
                "ignore": "Se você não solicitou uma redefinição de senha, nenhuma ação adicional será necessária."
            },
            "whoops": "Ops!",
            "hello": "Olá!",
            "regards": "Saudações",
            "troubles": "Se você tiver problemas para clicar no botão \"{actionText}\", copie e cole o link abaixo\nno seu navegador web: [{displayableActionUrl}]({actionURL})"
        },
        "datatables": {
            "select": {
                "rows_0": "Clique em uma linha para selecioná-la",
                "rows_1": "Apenas 1 linha selecionada",
                "rows_n": "Você selecionou %d linhas"
            }
        }
    }
}
