<template>
    <div>
      <form :action="route" method="post" id="form" @submit.prevent="onSubmit">
        <input type="hidden" name="_token" :value="csrf">
        <input type="hidden" name="token" :value="token">

        <div class="row">
          <div class="col-md-12">
            <misc-input type="email" :label="$t('app.users.email')" name="email" prefix="passwordReset" suffix="email" icon="fas fa-envelope" tooltip="password_reset_email" validate="required" feedback="true"></misc-input>
          </div>
        </div>

          <!-- <user-password preffix="passwordReset" col="12" @password="password=$event" @confirmation="passwordConfirmation=$event"></user-password> -->
        <user-password name="password_reset" feedback="true" prefix="passwordReset" col="col-12"></user-password>

        <div class="row">
          <div class="col-md-6">
            <button type="button" @click="this.location.href=login" class="btn btn-outline-dark btn-block margin_bottom" >{{ $t('buttons.back_to_login') }}</button>
          </div>
          <div class="col-md-6">
            <button type="submit" class="btn btn-primary margin_bottom btn-block">{{ $t('buttons.submit_reset') }}</button>

          </div>
        </div>

      </form>
    </div>
</template>

<script>
import {validateForm} from '../../mixins/Validation';
import { setTableEnabled } from '../../mixins/DataTables';

const passwordReset = {
    state : {
        email: '',
        password: '',
        passwordConfirmation: '',
    },
    mutations: {
        resetPasswordResetState(state) {
            state.username = '';
            state.email = '';
            state.password = '';
            state.passwordConfirmation = '';
        },
        passwordResetEmail(state, value) {
            state.email = value;
        },
        passwordResetPassword(state, value){
            state.password = value;
        },
        passwordResetPasswordConfirmation(state, value){
            state.passwordConfirmation = value;
        },
    }
}

export default {
    data: () => ({
    }),
    props: [
        'route',
        'csrf',
        'login',
        'token',
        'old'
    ],
    mounted() {
        setTableEnabled(0);
        this.fillOldEmail();
    },
    created() {
            this.$store.registerModule("passwordReset", passwordReset);
    },
    computed: {
        email() {
            return this.$store.state.email;
        },
        password: {
            get() {
                return '';
            },
            set(value) {
                this.$store.commit(`passwordResetPassword`, value);
            }
        },
    },
    methods: {
        onSubmit(){
            let isOk = validateForm('#form');
            if(isOk){
                document.getElementById('form').submit();
            }
        },
        fillOldEmail() {
            this.$store.commit(`passwordResetEmail`, this.old);
        }
    }
} //END OF EXPORT DEFAULT
</script>
