<template>
  <div class="modal-footer">
    <button type="button" @click="$emit('cancel'); closeModal()"  class="btn btn-outline-dark footer_buttons mr-auto cancel"><i class="fas fa-times"></i> {{ $t('buttons.cancel') }}</button>
    <button v-if="save" type="button" @click="$emit('save')" class="btn btn-outline-primary footer_buttons save" data-effect="primary_button"> <i class="far fa-save"></i> {{ $t('buttons.save') }}</button>
  </div>
</template>

<script>
import {showSwal} from '../../mixins/Swal';

export default {
  data: function(){
    return {
      value: 'true'
    } //END OF RETURN
  }, //END OF DATA: FUNCTION
  props: [
      'save'
  ],
  methods: {
    closeModal() {
      //IF ANY FIELDS HAVE DATA-OLD-VALUE SET, IT MEANS THAT THE MODAL IS IN EDIT MODEL
      if($('#form [data-old-value]').length > 0){
        //FIRST VERIFY IF THE FIELDS HAVE ANY OF ITS VALUES DIFFERENT FROM DE THE BEGINNING
        let fields = $('[data-old-value]');

        //VARIABLE THAT GRASP IF THERE IS AT LEAST A CHANGE INTO THE FORM
        var sentinel = false;
//PAROU AQQUI O FIELDS .VALUE RETORNA NULL TENTAR RESOLVER COM VUEX******************************************************
        for(let i=0; i < fields.length; i++){
          if(fields[i].getAttribute('data-old-value') != fields[i].value ){
            sentinel = true;
            break;
          }
        } //END OF FOR

        //SO IF SENTINEL IS TRUE... IT MEANS THAT A CHANGE HAS TAKEN PLACE
        //WARN THE USER THAT HE WILL LOOSE ALL DATA IF THE MODAL IS CLOSED
        if(sentinel == true){
          swal({
            title: trans.getString('app.common.warnings.sure'),
            text: trans.getString('app.common.warnings.changes'),
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#ffc107',
            cancelButtonText: 'No',
            cancelButtonColor: '#e0e0e0',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            onOpen: function(){
              $('.swal2-question').addClass('swal-icon-warning');
              $('.swal2-question').html(swalIconQuestion);
            } //END OF ONOPEN: FUNCTION()
          }).then((result) => {
            if (result.value) {
              $('#modalView').modal('hide');
            }
          }); //END OF SWALL .THEN
          //BREAK THE EXECUTION
          let fields = $('[data-old-value]');
          for(let i=0; i < fields.length; i++){
            if(!isEmpty(fields[i])){
              fields[i].removeAttribute('data-old-value');
            }
          } //END OF FOR

          return;
        } //END OF IF SENTINEL
        // $('#modal').modal('hide');
      } //END OF IF($('#FORM [DATA-OLD-VALUE]').LENGTH > 0)
      //NOW BEFORE CLOSE THE MODAL REMOVE THE ATTRIBUTE DATA-OLD-VALUE FROM ALL ELEMENTS
      //NOW
      //FINALLY CLOSE THE MODAL
      $('#modal').modal('hide');
      $('#modalView').modal('hide');
    } //END OF METHOD CLOSEMODAL
  } // END OF METHODS
} //END OG EXPORT DEFAULT
</script>
