<template>
  <div class="card-footer">
    <button  type="button" @click="$emit('save')" class="btn btn-primary footer_buttons save" data-effect="primary_button"> <i class="far fa-save"></i> {{ $t('buttons.save') }}</button>
  </div>
</template>

<script>
import { setTableEnabled } from '../../mixins/DataTables';

export default {
  data: ()=>({
    }),
  props: [
      'save'
  ],
  created() {
    setTableEnabled(0);
  },
  methods: {

  } // END OF METHODS
} //END OG EXPORT DEFAULT
</script>
