import {
    showSwal
} from './Swal';
import {
    getButtonColor,
    restartForm,
    toUpperCamelCase,
    initializeResposiveTablePopover
} from './Utils';

import {
    validateElement
} from './Validation';

//VARIABLE TO GRASP THE TABLE TO BE USED EVERYWHERE
var routes;
var table;
var vueImport;
var hasTable = 1;

var trashView = 0;

var momentFormat = '';

var operator = '';

var switchButton = '<div id="trash" class="form-check checkbox-slider-md checkbox-slider--b-flat checkbox-slider-danger"> \
<label> \
<input class="form-check-input" type="checkbox" id="toggleDelete" disabled=true> \
<span> \
<i class="fa fa-trash" style="font-size: 20px; margin-top: 5px"></i> \
</span> \
</label> \
</div>';

export default {
    methods: {
        test() {},

    } //END OF METHODS
} //END OF EXPORTS DEFAULT


$(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    });

    if (hasTable == 1) {
        //DISABLE THE ALERTS SHOWN WHEN AN ERROR IS THROWN FROM DATATABLES
        $.fn.dataTable.ext.errMode = 'none';

        $.fn.dataTable.moment(momentFormat);

        $.fn.dataTable.ext.buttons.select_all = {
            "extend": 'selectAll',
            "text": '<i class="fa fa-check-square"></i>',
            "titleAttr": trans.getString('buttons.all'),
            "className": "datatable_select_all"
        };

        $.fn.dataTable.ext.buttons.deselect_all = {
            "extend": 'selectNone',
            "text": '<i class="fa fa-square"></i>',
            "titleAttr": trans.getString('buttons.none'),
            "className": "datatable_deselect_all"
        };

        $.fn.dataTable.ext.buttons.toggle = {
            "text": '<i class="fa fa-check-square"></i>',
            "titleAttr": trans.getString('buttons.all'),
            "className": "datatable_toggle datatable_select_all",
        };

        $.fn.dataTable.ext.buttons.new = {
            "text": '<i class="fa fa-plus-square"></i>',
            "titleAttr": trans.getString('buttons.new'),
            "className": "datatable_new"
        };

        $.fn.dataTable.ext.buttons.edit = {
            "text": '<i class="fa fa-edit"></i>',
            "titleAttr": trans.getString('buttons.edit'),
            "enabled": false,
            "className": "datatable_edit"
        };

        $.fn.dataTable.ext.buttons.delete = {
            "text": '<i class="fa fa-trash"></i>',
            "titleAttr": trans.getString('buttons.delete'),
            "enabled": false,
            "className": "datatable_delete"
        };

        $.fn.dataTable.ext.buttons.undelete = {
            "text": '<i class="fa fa-undo"></i>',
            "titleAttr": trans.getString('buttons.restore'),
            "enabled": false,
            "className": "datatable_undelete"
        };

        $.fn.dataTable.ext.buttons.toggleDelete = {
            "text": '<i class="fa fa-trash"></i>',
            "titleAttr": trans.getString('buttons.delete'),
            "enabled": false,
            "className": "datatable_toggle_delete datatable_delete",
        };

        $.fn.dataTable.ext.buttons.forceDelete = {
            "text": '<i class="fa fa-trash"></i>',
            "titleAttr": trans.getString('buttons.force_delete'),
            "className": "datatable_force_delete",
            "enabled": false
        };

        $.fn.dataTable.ext.buttons.exportPrint = {
            "extend": "print",
            "text": '<i class="fa fa-print"></i>',
            "titleAttr": trans.getString('buttons.export.print'),
            "className": "datatable_select_all"
        };

        $.fn.dataTable.ext.buttons.exportCsv = {
            "extend": "csv",
            "text": '<i class="fa fa-file-excel"></i>',
            "titleAttr": trans.getString('buttons.export.csv'),
            "className": "datatable_select_all"
        };

        $.fn.dataTable.ext.buttons.exportPdf = {
            "extend": "pdf",
            "text": '<i class="fa fa-file-pdf"></i>',
            "titleAttr": trans.getString('buttons.export.pdf'),
            "className": "datatable_select_all"
        };

        $.fn.dataTable.ext.buttons.export = {
            "extend": 'collection',
            "text": '<i class="fa fa-download"></i>',
            "titleAttr": trans.getString('buttons.export'),
            "className": "datatable_select_all",
            "buttons": [{
                    "extend": "exportCsv"
                },
                {
                    "extend": "exportPdf"
                },
                {
                    "extend": "exportPrint"
                }
            ]
        }

        $.fn.dataTable.ext.buttons.refresh = {
            "text": '<i class="fa fa-sync"></i>',
            "titleAttr": trans.getString('buttons.refresh'),
            "className": "datatable_select_all"
        };

        $.fn.dataTable.ext.buttons.view = {
            "text": '<i class="fa fa-eye"></i>',
            "titleAttr": trans.getString('buttons.view'),
            "enabled": false,
            "className": "datatable_view"
        };

        if ($('#tableDataTables').length == 1) {
            routes = {
                'edit': laroute.route(`${$('#tableDataTables').attr('data-action')}.get`),
                'table': laroute.route(`${$('#tableDataTables').attr('data-action')}.table_data`),
            };
        } else if ($('#tableDataTablesPrintJobs').length == 1) {
            routes = {
                'table': laroute.route(`${$('#tableDataTablesPrintJobs').attr('data-action')}.table_data`),
                'parameters': $('#tableDataTablesPrintJobs').attr('data-parameters')
            };
        } else if ($('#tableDataTablesPendingSolicitation').length == 1) {
            routes = {
                'edit': laroute.route(`${$('#tableDataTablesPendingSolicitation').attr('data-action')}.get`),
                'check': laroute.route(`${$('#tableDataTablesPendingSolicitation').attr('data-action')}.can_make`),
                'table': laroute.route(`${$('#tableDataTablesPendingSolicitation').attr('data-action')}.table_data`),
                'parameters': $('#tableDataTablesPendingSolicitation').attr('data-parameters')
            };
            operator = $("#tableDataTablesPendingSolicitation").attr("data-operator");
        } else {
            routes = {};
        }

        try {

            if ($('#tableDataTables').length == 1) {
                table = $('#tableDataTables').DataTable(
                    //MOUNT THE DATABLE DESIGN ON THE VIEW
                    $.extend(selectAbleDT, {
                        order: [
                            [1, 'asc']
                        ],
                        sDom: "<'row'<'col-sm-12 col-md-5'B><'col-4 col-md-1'<'toolbar'>><'col pull-right'f>>" +
                            "<'row'<'col-sm-12'tr>>" +
                            "<'row'<'col-sm-6 pull-left'l><'col-sm-6 pull-right'p>>" +
                            "<'row'<'col-md-12 pull-left'i>>",
                        buttons: [{
                                extend: "toggle",
                                "action": toggleSelected
                            },
                            {
                                extend: "new",
                                "action": newRow
                            }, //ADD BUTTON NEW
                            {
                                extend: "edit",
                                "action": editRow
                            }, //ADD BUTTON EDIT
                            {
                                extend: "undelete",
                                "action": restoreRow
                            }, //ADD BUTTON DELETE AND FORCEDELETE,
                            {
                                extend: "toggleDelete",
                                "action": deleteModeRow
                            }, //ADD BUTTON RESTORE
                            // { extend: "export" }
                        ],
                        ajax: {
                            url: routes.table,
                            type: 'POST',
                            data: function (d) {
                                d['trashed'] = trashView;
                                d['operation'] = 'table_data';
                            },
                            dataSrc: function (json) {
                                //Make your callback here.
                                //alert("Done!");
                                $('#pagecount h3').text(json.count);
                                return json.data;
                            },
                            beforeSend: function () {
                                // Show image container
                                $("#loader").show();
                            },
                            error: function (response) {
                                console.error(response);
                            },
                            complete: function (data) {
                                // Hide image container
                                $("#loader").hide();
                            },
                        },
                        fnDrawCallback: function (oSettings) { //TI TA BUTTON USED TO CHANGE THE VIEW OF ACTIVE OR DELETED
                            //IF THE SOFTDELETE DISABLED SET IN COMPONENT WAS NOT OVERWRITE IN THIS VIEW CHANGING ITS VALUE. PUT THE TI-TA BUTTON
                            if (softdeleteDisabled == 0) {
                                if ($('#tableDataTables').attr('data-softdelete') == 'false') {
                                    //IF THE ATTRIBUTE DOESNT EXIST, OR IS SET AS TRUE OR UNDEFINED THE TRASH BUTTON WILL DISPLAY
                                } else {
                                    $("div.toolbar").html(switchButton); //ADD THE BUTTON
                                }
                                $('#toggleDelete').on('change', function () {
                                    toggleDelete(this);
                                });
                                $('#toggleDelete').parents('#trash').attr('title', trans.getString('buttons.show_trash')); //SET ITS PLACEHOLDER
                                if (trashView == 1) { // IF TRASHVIEW IS 1, IT MEANS THAT TRASH VIEW IS TURNED ON
                                    $('#toggleDelete').prop('checked', true); // MARK IT AS CHECKED
                                    $('#toggleDelete').next().attr('class', 'text-danger'); //CHANGE ITS COLOUR TO BOOTSTRAP DANGER
                                    $('#toggleDelete').parents('#trash').attr('title', trans.getString('buttons.exit_trash'));
                                } else { //IF TRAHSVIEW IS 0, IT MEANS THAT TRASH VIEW IS TURNED OFF
                                    $('#toggleDelete').next().attr('class', ''); //CHANGE ITS COLOUR TO DEFAULT REMOVING ALL CLASSES
                                    $('#toggleDelete').parents('#trash').attr('title', trans.getString('buttons.show_trash'));
                                }

                                // ENABLE CHECKBOX
                                $('#toggleDelete').prop('disabled', false);
                            } //END FO IF(SOFTDELETEDISABLE == 0)
                        } //END OF "fnDrawCallback"
                    }) //END OF $.EXTEND );
                ); //END OF DATATABLES FUNCTION

                // SELECT  DATATABLES EVENT
                table.on('select', function () {
                    toggleSelectedIcon();
                    var selectedRows = table.rows({
                        selected: true
                    }).count(); //GET HOW MUCH ROWS ARE SELECTED
                    var canDelete = 0; //BOOLEAN WHICH CONTROLS DELETE PERMISSION
                    table.rows({
                        selected: true
                    }).every(function (rowIdx, tableLoop, rowLoop) {
                        canDelete = $(table.row(rowIdx).node()).attr('data-candelete'); //SET OR UNSET DELETE PERMISSION
                    });
                    //IMPLICIT CONDITION INTO ENABLE FUNCTION, IF TRASHVIEW IS TURNED OFF
                    //AND JUST A ROW IS SELECTED ENABLES THE DATATABLES' BUTTON 'EDIT'
                    table.button(2).enable(selectedRows === 1 && trashView == 0 && editDisabled == 0);
                    //IMPLICIT CONDITION INTO ENABLE FUNCTION, IF TRASHVIEW IS TURNED OFF
                    //AND THERE IS AT LEAST ONE ROW SELECTED ENABLES THE DATATABLES' BUTTON 'DELETE AND RESTORE'
                    table.button(3).enable(selectedRows > 0 && trashView == 1 && softdeleteDisabled == 0);
                    //IMPLICIT CONDITION INTO ENABLE FUNCTION, IF TRASHVIEW IS TURNED ON
                    //AND THERE IS AT LEAST ONE ROW SELECTED ENABLES THE DATATABLES' BUTTON 'FORCEDELETE'
                    table.button(4).enable(selectedRows > 0);


                    //GRASP THE VALUES OF ID OF THE SELECTED ROW TO MAKE THE *EXTENSION MULTIPLE SELECT2 WITH THE SELECTED *EXTENSIONS ON EDIT FUNCTION
                    var row = table.rows({
                        selected: true
                    }).data();
                }); //END OF TABLE.ON

                //DESELECT  DATATABLES EVENT
                table.on('deselect', function () {
                    toggleSelectedIcon();
                    var selectedRows = table.rows({
                        selected: true
                    }).count(); //GET HOW MUCH ROWS ARE SELECTED
                    var canDelete = 0; //BOOLEAN WHICH CONTROLS DELETE PERMISSION
                    table.rows({
                        selected: true
                    }).every(function (rowIdx, tableLoop, rowLoop) {
                        canDelete = $(table.row(rowIdx).node()).attr('data-candelete'); //SET OR UNSET DELETE PERMISSION
                    });
                    //IMPLICIT CONDITION INTO ENABLE FUNCTION, IF TRASHVIEW IS TURNED OFF
                    //AND JUST A ROW IS SELECTED ENABLES THE DATATABLES' BUTTON 'EDIT'
                    table.button(2).enable(selectedRows === 1 && trashView == 0 && editDisabled == 0);
                    //IMPLICIT CONDITION INTO ENABLE FUNCTION, IF TRASHVIEW IS TURNED OFF
                    //AND THERE IS AT LEAST ONE ROW SELECTED ENABLES THE DATATABLES' BUTTON 'DELETE AND RESTORE'
                    table.button(3).enable(selectedRows > 0 && trashView == 1 && softdeleteDisabled == 0);
                    //IMPLICIT CONDITION INTO ENABLE FUNCTION, IF TRASHVIEW IS TURNED ON
                    //AND THERE IS AT LEAST ONE ROW SELECTED ENABLES THE DATATABLES' BUTTON 'FORCEDELETE'
                    table.button(4).enable(selectedRows > 0);

                }); //END OF TABLE.ON('DESELECT', FUNCTION ()
            } else if ($('#tableDataTablesPrintJobs').length == 1) {
                table = $('#tableDataTablesPrintJobs').DataTable(
                    //MOUNT THE DATABLE DESIGN ON THE VIEW
                    $.extend(selectAbleDT, {
                        order: [
                            [1, 'asc']
                        ],
                        sDom: "<'row'<'col-sm-12 col-md-5'B><'col-4 col-md-1'<'toolbar'>><'col pull-right'f>>" +
                            "<'row'<'col-sm-12'tr>>" +
                            "<'row'<'col-sm-6 pull-left'l><'col-sm-6 pull-right'p>>" +
                            "<'row'<'col-md-12 pull-left'i>>",
                        buttons: [{
                                extend: "refresh",
                                "action": reloadTable
                            },
                            // { extend: "export" }
                        ],
                        ajax: {
                            url: routes.table,
                            type: 'POST',
                            data: function (d) {
                                for (const [key, value] of Object.entries(JSON.parse(routes.parameters))) {
                                    d[key] = value
                                };
                                d['operation'] = 'table_data';
                            },
                            dataSrc: function (json) {
                                //Make your callback here.
                                //alert("Done!");
                                $('#pagecount h3').text(json.count);
                                return json.data;
                            },
                            beforeSend: function () {
                                // Show image container
                                $("#loader").show();
                            },
                            error: function (response) {
                                console.error(response);
                            },
                            complete: function (data) {
                                // Hide image container
                                $("#loader").hide();
                            }
                        },
                        "order": [
                            [0, "desc"],
                            [1, 'desc']
                        ],
                        "stateSave": false,
                        scrollX: true
                    }) //END OF $.EXTEND );
                ); //END OF DATATABLES FUNCTION
            } else if ($('#tableDataTablesPendingSolicitation').length == 1) {
                table = $('#tableDataTablesPendingSolicitation').DataTable(
                    //MOUNT THE DATABLE DESIGN ON THE VIEW
                    $.extend(selectAbleDT, {
                        order: [
                            [1, 'asc']
                        ],
                        sDom: "<'row'<'col-sm-12 col-md-5'B><'col-4 col-md-1'<'toolbar'>><'col pull-right'f>>" +
                            "<'row'<'col-sm-12'tr>>" +
                            "<'row'<'col-sm-6 pull-left'l><'col-sm-6 pull-right'p>>" +
                            "<'row'<'col-md-12 pull-left'i>>",
                        buttons: [{
                                extend: "new",
                                "action": newRow
                            },
                            {
                                extend: "edit",
                                "action": editSolicitation
                            },
                            {
                                extend: "view",
                                "action": viewRow
                            },
                            {
                                extend: "toggleDelete",
                                "action": deleteSolicitation
                            },
                            {
                                extend: "refresh",
                                "action": reloadTable
                            },
                            // { extend: "export" }
                        ],
                        ajax: {
                            url: routes.table,
                            type: 'POST',
                            data: function (d) {
                                for (const [key, value] of Object.entries(JSON.parse(routes.parameters))) {
                                    d[key] = value
                                };
                                d['operator'] = operator;
                                d['operation'] = 'table_data';
                            },
                            beforeSend: function () {
                                // Show image container
                                $("#loader").show();
                            },
                            error: function (response) {
                                console.error(response);
                            },
                            complete: function (data) {
                                // Hide image container
                                $("#loader").hide();
                            }
                        },
                        fnDrawCallback: function (oSettings) {

                            checkCanMake();

                            if (operator == 'requester') {
                                table.button(0).enable(analyzeMode == 0 && canMake > 0);
                            } else {
                                table.buttons('.datatable_new').remove();
                            }
                            table.button((operator == 'requester') ? 1 : 0).enable(false);
                            table.button((operator == 'requester') ? 2 : 1).enable(false);
                            table.button((operator == 'requester') ? 3 : 2).enable(false);

                            table.column(1).visible(analyzeMode == 1);

                        },
                        "orderFixed": [
                            [5, "desc"],
                            [2, "asc"]
                        ],
                        "stateSave": false,
                        "columnDefs": [{
                            "targets": [5],
                            "visible": false,
                            "searchable": false
                        }],
                        scrollX: true
                    }) //END OF $.EXTEND );
                ); //END OF DATATABLES FUNCTION

                // SELECT  DATATABLES EVENT
                table.on('select', function () {
                    var selectedRows = table.rows({
                        selected: true
                    }).count(); //GET HOW MUCH ROWS ARE SELECTED
                    var canEdit = 1; //BOOLEAN WHICH CONTROLS DELETE PERMISSION

                    table.rows({
                        selected: true
                    }).every(function (rowIdx, tableLoop, rowLoop) {
                        canEdit = table.cell(rowIdx, 5).data(); //SET OR UNSET DELETE PERMISSION
                    });

                    if (operator == 'requester') {
                        table.button(0).enable(selectedRows == 0 && analyzeMode == 0 && canMake > 0);
                    }
                    table.button((operator == 'requester') ? 1 : 0).enable(selectedRows == 1 && canEdit == 1);
                    table.button((operator == 'requester') ? 2 : 1).enable(selectedRows == 1);
                    table.button((operator == 'requester') ? 3 : 2).enable(selectedRows >= 1 && canEdit == 1 && analyzeMode == 0);

                    //GRASP THE VALUES OF ID OF THE SELECTED ROW TO MAKE THE *EXTENSION MULTIPLE SELECT2 WITH THE SELECTED *EXTENSIONS ON EDIT FUNCTION
                    var row = table.rows({
                        selected: true
                    }).data();
                }); //END OF TABLE.ON

                //DESELECT  DATATABLES EVENT
                table.on('deselect', function () {
                    var selectedRows = table.rows({
                        selected: true
                    }).count(); //GET HOW MUCH ROWS ARE SELECTED
                    var canEdit = 1; //BOOLEAN WHICH CONTROLS DELETE PERMISSION

                    table.rows({
                        selected: true
                    }).every(function (rowIdx, tableLoop, rowLoop) {
                        canEdit = table.cell(rowIdx, 5).data(); //SET OR UNSET DELETE PERMISSION
                    });

                    if (operator == 'requester') {
                        table.button(0).enable(selectedRows == 0 && analyzeMode == 0 && canMake > 0);
                    }
                    table.button((operator == 'requester') ? 1 : 0).enable(selectedRows == 1 && canEdit == 1);
                    table.button((operator == 'requester') ? 2 : 1).enable(selectedRows == 1);
                    table.button((operator == 'requester') ? 3 : 2).enable(selectedRows >= 1 && canEdit == 1 && analyzeMode == 0);


                    //GRASP THE VALUES OF ID OF THE SELECTED ROW TO MAKE THE *EXTENSION MULTIPLE SELECT2 WITH THE SELECTED *EXTENSIONS ON EDIT FUNCTION
                    var row = table.rows({
                        selected: true
                    }).data();

                }); //END OF TABLE.ON('DESELECT', FUNCTION ()
            } else {
                console.error('Datatables not found 2! AJAX');
            }
        } catch (e) {
            //JUST CATCH THE ERROR OF DATATABLES ROUTE WHEN IT DOES NOT EXISTS
            console.error('Datatables not found! AJAX');
        }

        //MAKE THE TABLE INVISIBLE THIS CODE IS NECESSARY TO AVOID THE BUG WITH DATATABLE SCROLL OVERLAY THE MODALS
        $('#modal').on('shown.bs.modal', () => {
            $('#tableDataTables').addClass('d-none');
            $('#tableDataTablesPrintJobs').addClass('d-none');
        });

        //RESET THE FORM COMPONENTS ON VUEX
        $('#modal').on('hidden.bs.modal', () => {
            //FIRST THING TO DO IS MAKE THE TABLE VISIBLE AGAIN THIS CODE IS NECESSARY TO AVOID THE BUG WITH DATATABLE SCROLL OVERLAY THE MODALS
            $('#tableDataTablesPrintJobs').addClass('d-none');
            $('#tableDataTables').removeClass('d-none');

            reloadTable();

            //WHEN THE MODAL CLOSE CLEAR THE MODAL DATA
            store.commit(`reset${toUpperCamelCase($('#tableDataTables').attr('data-action'))}State`);
            //AND IF THERE IS A NAV-TABS MARK THE FIRST TAB AS ACTIVE
            var event = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': true
            });

            //WORKAROUND TO VUE-TEL-INPUT CLEAR THE FIELDS
            var intls = document.getElementsByClassName('vue-tel-input');
            for (let i = 0; i < intls.length; i++) {
                intls[i].querySelector('input').value = "";
            }

            var inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].value = "";
            }


            //VERIFY IF THERE IS A MENU NAV-TABS
            let menu = document.getElementsByClassName('nav-tabs')[0];

            //IF MENU DOES EXIST, SO FORCE THE FIRST TAB TO BE ACTIVATED
            if (!isEmpty(menu)) {
                menu.querySelector('a').dispatchEvent(event);
            }

            //WORKAROUND TO FEEDBACK WORKS WITH VUE-SELECT
            setTimeout(
                () => {
                    restartForm('#form');
                }, 300);
        });

    }

}); //END OF JQUERY'S FUNCTION

function newRow() {
    $('#modal').modal();

} //END OF NEWROW

function editRow() {

    //IF THE COUNT IS EQUALS 1 IT MEANS THAT THERE IS JUST ONE ROW SELECTED, SO THE FUNCTION CAN BE EXECUTED
    if (table.rows({
            selected: true
        }).count() == 1) {
        //GET JUST THE SELECTED ID FROM THE OBJECT DATATABLES
        let selectedId = table.rows({
            selected: true
        }).data()[0][0];

        let object = {
            id: selectedId,
            operation: 'read'
        };

        //EXECUTE THE AJAX
        axios.post(routes.edit, object).then(function (response) {

            if (response.data.status == 'success') {

                //SHOW THE MODAL
                $('#modal').modal();

                let keys = Object.keys(response.data.data);

                keys.map((key) => {
                    //THESES FIELDS BELOW WILL NEVER BE PROCESSED BY THE EDIT DATATABLES BECAUSE THERE ARE NOT RELEVANT AND AVOID AN EXCEPTION
                    if (key != 'created_at' && key != 'updated_at' && key != 'deleted_at') {
                        // GET THE ROUTE ACTION OF THE VISIBLE TABLE
                        let className = toUpperCamelCase($('#tableDataTables').attr('data-action'));
                        //FORCE A TRUE CAMELCASE
                        className = className.substring(0, 1).toLowerCase() + className.substring(1);

                        store.commit(className + toUpperCamelCase(key), response.data.data[key]);

                        //A WORKAROUND TO FIX THE PROBLEM WITH THE COMPONENT INTL-TEL-INPUT, IT DOESN'T SHOW THE VALUE ON READ, SO WE'LL PUSH IT TO DO THAT
                        //AND ALSO THEVUEMASK
                        let input = document.getElementsByName(key)[0];
                        //FIRST VERIFY IF THE COMPONENT EXISTS TO AVOID AN EXCEPTION
                        if (!isEmpty(input)) {
                            //THEN MAKE SURE THAT THE COMPONENT IS A VUE-TEL-INPUT
                            if (input.classList.contains('vue-tel-input')) {
                                //FINALLY SET THE VALUE DIRECTLY TO THE FIELD
                                input.querySelector('input').value = response.data.data[key];
                                //AND SIMULATE AND EVENT INPUT TO FORCE THE CORRECT MASK PRESENTATION
                                input.querySelector('input').dispatchEvent(new Event('input'));
                            } else if (input.classList.contains('nin')) {
                                //FINALLY SET THE VALUE DIRECTLY TO THE FIELD
                                input.value = response.data.data[key];
                                //AND SIMULATE AND EVENT INPUT TO FORCE THE CORRECT MASK PRESENTATION
                                input.dispatchEvent(new Event('input'));
                            } else {
                                //FINALLY SET THE VALUE DIRECTLY TO THE FIELD
                                input.value = response.data.data[key];
                            }
                            //FOR THE FIELD TO BE VALIDATED
                            setTimeout(() => {
                                validateElement(input);
                            }, 400);
                        } //END IF(!EMPTY ...)
                    } //END OF IF(KEY...)
                }); //END OF KEYS.MAP
                setTimeout(() => {
                    let forceValidation = document.querySelectorAll('#form [aria-invalid="true"]');

                    for (let i = 0; i < forceValidation.length; i++) {
                        validateElement(forceValidation[i]);
                    }
                }, 500);
            } else {
                if (response.data.title == '') {
                    response.data.title = trans.getString('app.common.errors.fatal');
                    response.data.text = trans.getString('app.common.errors.unexpected');
                }
                showSwal('error', response.data.title, response.data.text);
            }
        }); //END OF PROMISE'S THEN
     //END OF IF(TABLE.ROWS({SELECTED: TRUE}).COUNT())
    } else {
        let title = trans.getString('app.common.errors.fatal');
        let text = trans.getString('app.common.errors.unexpected');
        showSwal('error', title, text);
    }
    //BEFORE FINISH THIS METHOD MAKE ALL INPUT RESPONSIVE IN DINAMIC TABLES POPOVERABLE
    initializeResposiveTablePopover();

} //END OF EDITROW

// GET THE ROUTE ACTION OF THE VISIBLE TABLE
let className = toUpperCamelCase($('#tableDataTablesPendingSolicitation').attr('data-action'));
//FORCE A TRUE CAMELCASE
className = className.substring(0, 1).toLowerCase() + className.substring(1);

function editSolicitation() {

    //IF THE COUNT IS EQUALS 1 IT MEANS THAT THERE IS JUST ONE ROW SELECTED, SO THE FUNCTION CAN BE EXECUTED
    if (table.rows({
            selected: true
        }).count() == 1) {

        //GET JUST THE SELECTED ID FROM THE OBJECT DATATABLES
        let selected = table.rows({
            selected: true
        }).data()[0];

        let object = {
            id: selected[0],
            operation: (analyzeMode == 0) ? 'read' : 'view',
            ctrl: selected[6]
        };

        //EXECUTE THE AJAX
        axios.post(routes.edit, object).then(function (response) {

            if (response.data.status == 'success') {
                let keys = Object.keys(response.data.data);

                keys.map((key) => {
                    //THESES FIELDS BELOW WILL NEVER BE PROCESSED BY THE EDIT DATATABLES BECAUSE THERE ARE NOT RELEVANT AND AVOID AN EXCEPTION
                    if (key != 'created_at' && key != 'updated_at' && key != 'deleted_at') {

                        // GET THE ROUTE ACTION OF THE VISIBLE TABLE
                        let className = toUpperCamelCase($('#tableDataTablesPendingSolicitation').attr('data-action'));
                        //FORCE A TRUE CAMELCASE
                        className = className.substring(0, 1).toLowerCase() + className.substring(1);

                        store.commit(className + toUpperCamelCase(key), response.data.data[key]);
                    }

                    // SHOW THE MODAL
                    $('#modal').modal();


                    $("#modal input[name='value_approved']").attr("max", response.data.max);
                    $("#modal input[name='value']").attr("max", response.data.max);
                    $("#modal input[name='value_approved']").attr("min", 0);
                }); //END OF KEYS.MAP
            } else {
                if (response.data.title == '') {
                    response.data.title = trans.getString('app.common.errors.fatal');
                    response.data.text = trans.getString('app.common.errors.unexpected');
                }
                showSwal('error', response.data.title, response.data.text);
            }
        }); //END OF PROMISE'S THEN
     //END OF IF(TABLE.ROWS({SELECTED: TRUE}).COUNT())
    } else {
        let title = trans.getString('app.common.errors.fatal');
        let text = trans.getString('app.common.errors.unexpected');
        showSwal('error', title, text);
    }
} //END OF VIEWROW

function viewRow() {

    //IF THE COUNT IS EQUALS 1 IT MEANS THAT THERE IS JUST ONE ROW SELECTED, SO THE FUNCTION CAN BE EXECUTED
    if (table.rows({
            selected: true
        }).count() == 1) {
        //GET JUST THE SELECTED ID FROM THE OBJECT DATATABLES
        let selected = table.rows({
            selected: true
        }).data()[0];

        let object = {
            id: selected[0],
            operation: 'view',
            ctrl: selected[6]
        };

        //EXECUTE THE AJAX
        axios.post(routes.edit, object).then(function (response) {
            if (response.data.status == 'success') {

                //SHOW THE MODAL
                $('#modalView').modal();

                let keys = Object.keys(response.data.data);

                keys.map((key) => {
                    //THESES FIELDS BELOW WILL NEVER BE PROCESSED BY THE EDIT DATATABLES BECAUSE THERE ARE NOT RELEVANT AND AVOID AN EXCEPTION
                    if (key != 'created_at' && key != 'updated_at' && key != 'deleted_at') {
                        let value = '';
                        if (response.data.data[key] === null) {
                            value = '';
                        } else if (typeof response.data.data[key] === 'object') {
                            value = response.data.data[key]['label'];
                        } else {
                            value = response.data.data[key];
                        }
                        $('#modalView #' + key).html(value);
                    }
                }); //END OF KEYS.MAP
            } else {
                if (response.data.title == '') {
                    response.data.title = trans.getString('app.common.errors.fatal');
                    response.data.text = trans.getString('app.common.errors.unexpected');
                }
                showSwal('error', response.data.title, response.data.text);
            }
        }); //END OF PROMISE'S THEN
     //END OF IF(TABLE.ROWS({SELECTED: TRUE}).COUNT())
    } else {
        let title = trans.getString('app.common.errors.fatal');
        let text = trans.getString('app.common.errors.unexpected');
        showSwal('error', title, text);
    }
} //END OF VIEWROW



function deleteRow() {
    let selectedRows = table.rows({
        selected: true
    }).data().toArray();
    deleteOperations(selectedRows, vue.$routes.route(`${$('#tableDataTables').attr('data-action')}.delete`), 'delete');
}

function deleteSolicitationRow() {
    let selectedRows = table.rows({
        selected: true
    }).data().toArray();
    deleteOperations(selectedRows, vue.$routes.route(`${$('#tableDataTablesPendingSolicitation').attr('data-action')}.delete`), 'delete');
}

function restoreRow() {
    let selectedRows = table.rows({
        selected: true
    }).data().toArray();
    deleteOperations(selectedRows, vue.$routes.route(`${$('#tableDataTables').attr('data-action')}.delete`), 'restore');
}

function forceDeleteRow() {
    let selectedRows = table.rows({
        selected: true
    }).data().toArray();
    deleteOperations(selectedRows, vue.$routes.route(`${$('#tableDataTables').attr('data-action')}.delete`), 'force_delete');
}

// FUNCTION THAT CONTROLS THE TI TA BUTTON (TRASHVIEW) AND DATATABLES ENABLED BUTTON
function toggleDelete(toggle) {
    $('#toggleDelete').attr('disabled', true);

    if (toggle.checked) { //IF TI TA IS CHECKED
        trashView = 1; //SET 1 TO TRASHVIEW
    } else { //IF TI TA ISN'T CHECKED
        trashView = 0; //SET 1 TO TRASHVIEW
    }

    var datatableRoute = routes.table;
    //REBUILD THE STRING TO BE USED IN AJAX
    //REDESIGN THE TABLE
    table.ajax.url(datatableRoute.replace('{trashed}', trashView)).load();

    table.rows().deselect(); //FORCE A DESELECT ALL ROWS, ONCE A TRASH VIEW IS TRIGGERED

    toggleDeleteIcon();
} //END OF FUNCTION TOGGLEDELETE(TOGGLE)

//SELECT WHAT'S THE FUNCTION THE BUTTON SELECT WILL BE EXECUTED
function toggleSelected() {
    if (table.rows({
            selected: true
        }).count() > 0) {
        table.rows().deselect();
    } else {
        table.rows().select();
    }
    toggleSelectedIcon();
} //END OF FUNCTION TOGGLESELECTED()

//CHANGE THE ICON, TEXT AND COLOR OF THE FIRST BUTTON OF DATATABLES, IN OTHER WORDS CHANGE THE FUNCTIONALITY BETWEEN SELECT AD DESELECT
function toggleSelectedIcon() {
    var button = $('.datatable_toggle');
    if (table.rows({
            selected: true
        }).count() > 0) {
        button.prop('title', trans.getString('buttons.none'));
        button.removeClass('datatable_select_all');
        button.addClass('datatable_deselect_all');
        button.find('span').html('<i class="fa fa-square"></i>');
    } else {
        button.prop('title', trans.getString('buttons.all'));
        button.addClass('datatable_select_all');
        button.removeClass('datatable_deselect_all');
        button.find('span').html('<i class="fa fa-check-square"></i>');
    }
} //END OF FUNCTION TOGGLESELECTEDICON()

//CHANGE THE VIEWS PROPERTIES OF THE DELETE BUTTON, LIKLE NAME COLORS AND ETC.
function toggleDeleteIcon() {
    var button = $('.datatable_toggle_delete');
    if (trashView == 1 && softdeleteDisabled == 0) {
        button.prop('title', trans.getString('buttons.force_delete'));
        button.removeClass('datatable_delete');
        button.addClass('datatable_force_delete');
        button.find('span').html('<i class="fa fa-trash"></i>');
    } else {
        button.prop('title', trans.getString('buttons.delete'));
        button.addClass('datatable_delete');
        button.removeClass('datatable_force_delete');
        button.find('span').html('<i class="fa fa-trash"></i>');
    }
} //END OF FUNCTION TOGGLEDELETEICON()

//SELECT WHAT'S THE FUNCTION THE BUTTON DELETE WILL BE EXECUTED
function deleteModeRow(e, dt, none, config) {
    if (trashView == 0 || softdeleteDisabled == 1) {
        deleteRow(e, dt, none, config);
    } else {
        forceDeleteRow(e, dt, none, config);
    }
} //END OF FUNCTION DELETEMODEROW(E, DT, NONE, CONFIG)

function deleteSolicitation(e, dt, none, config) {
    deleteSolicitationRow(e, dt, none, config);
} //END OF FUNCTION DELETEMODEROW(E, DT, NONE, CONFIG)

//METHOD WHICH CONTROLS THE ACTIONS DELETE, RESTORE, FORCEDELETE.
function deleteOperations(selectedRows, route, operation) {
    //CREATE THE VARIABLES TO KEEP ITS VALUES
    var buttonName;
    var buttonColor;
    var text;
    var colorClass;
    var cancelColor = getButtonColor("light");

    //GET THE CORRECT LABELS AND COLORS DEPENDING ON THE OPERATION VALUE
    if (operation == 'delete') {
        buttonName = trans.getString("buttons.delete");
        buttonColor = getButtonColor("danger");
        text = trans.getString("app.common.warnings.delete");
        colorClass = 'danger';
    } else if (operation == 'restore') {
        buttonName = trans.getString("buttons.restore");
        buttonColor = getButtonColor("restore");
        text = trans.getString("app.common.warnings.restore");
        colorClass = 'restore';
    } else {
        buttonName = trans.getString("buttons.force_delete");
        buttonColor = getButtonColor("error");
        text = trans.getString("app.common.warnings.force_delete");
        colorClass = 'error';
    }

    //REMOVE THE FOCUS OF ALL ELEMENTS TO AVOID THE B-U-G WITH PRESSING ENTER ON SWAL AND CLICKING ON THE LAST FOCUS BUTTON OF "DOCUMENT"
    $(':focus').blur();

    //SHOW THE SWAL OF OPERATION
    Swal.fire({
        title: trans.getString('app.common.warnings.sure'),
        text: text,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: buttonColor,
        confirmButtonText: buttonName,
        cancelButtonText: `<span style="color:black;">${trans.getString("buttons.cancel")}</span>`,
        cancelButtonColor: cancelColor,
        allowOutsideClick: false, //BLOCK THE CLOSING BY CLICKING OUTSIDE THE SWAL
        onOpen: function () {
            $('.swal2-question').addClass('faa-pulse animated swal-icon-' + colorClass);
            //   $('.swal2-question').html(swalIconQuestion);
            $('.swal2-confirm').css('box-shadow', `4px 5px 15px -5px  ${(operation == 'delete') ? 'crimson' : (operation == 'restore') ? 'deeppink' : 'darkred'}`);
            $('.swal2-cancel').css('box-shadow', '4px 5px 15px -5px gray');
        }
    }).then((result) => { //EXECUTED BEFORE .DONE, HERE YOU PUT EVERYTHING THAT YOU WANT TO DO WITH AJAX
        //IF THE USER DOES NOT CANCEL THE SWAL DO IT
        if (result.value != '' && result.value != undefined) {

            Swal.fire({
                title: trans.getString("app.common.warnings.password"),
                input: 'password',
                inputPlaceholder: trans.getString("app.users.password"),
                inputValidator: (value) => {
                    return !value && trans.getString("app.common.errors.password")
                },
                inputAttributes: {
                    'autocomplete': 'off'
                },
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: buttonColor,
                cancelButtonColor: cancelColor,
                confirmButtonText: trans.getString("buttons." + operation),
                cancelButtonText: `<span style="color:black;">${trans.getString("buttons.cancel")}<span>`,
                allowOutsideClick: false, //BLOCK THE CLOSING BY CLICKING OUTSIDE THE SWAL
                onOpen: function () {
                    $('.swal2-warning').addClass('faa-pulse animated swal-icon-' + colorClass);
                    //   $('.swal2-warning').html(swalIconWarning);
                    $('.swal2-confirm').css('box-shadow', `4px 5px 15px -5px  ${(operation == 'delete') ? 'crimson' : (operation == 'restore') ? 'deeppink' : 'darkred'}`);
                    $('.swal2-cancel').css('box-shadow', '4px 5px 15px -5px gray');
                }
            }).then((result) => {
                //IF THE USER DOES NOT CANCEL THE PASSWORD CONFIRMATION DO IT
                if (result.value != '' && result.value != undefined) {
                    $.ajax({
                        type: "POST",
                        dataType: 'JSON',
                        url: route,
                        data: {
                            password: result.value,
                            selectedRows: selectedRows,
                            operation: operation
                        },
                        success: function (response) { //ONCE THE .PRECONFIRM IS DONE, GIVE FEEDBACK TO THE USER

                            if (response.status == 'warning') { //IF AN PREVENTED ERROR WAS GOT
                                showSwal(response.status, response.title, response.text); //TRADUCTION (TRANS)TOOK PLACE ON SERVER'S SIDE
                            } else if (response.status == 'success') { //DELETE THE ROW MANUALLY FROM DATATABLES, AND THEN, SHOW A SUCCESS SWEETMODAL
                                showSwal('success', response.title); //RELOAD THE TABLE
                                reloadTable();
                                if (canMake != null) {
                                    canMake++;
                                }
                            } else {
                                showSwal('error', response.title, response.text);
                            }
                        }, //END OF SUCCESS
                    }); //END OF AJAX
                } //END OF IF (RESULT.VALUE != '' && RESULT.VALUE != UNDEFINED)
            }); //END OF SWALL .THEN
        } //END OF IF IF (RESULT.VALUE != '' && RESULT.VALUE != UNDEFINED)
    }); //END OF FIRST .THEN( FUNCTION()
} //END OF FUNCTION DELETEAJAX(SELECTEDROWS, FORM, ROUTE)

//RELOAD THE DATATABLES
export function reloadTable() {
    table.rows().deselect(); //FORCE A DESELECT ALL ROWS, ONCE A TRASH VIEW IS TRIGGERED

    table.ajax.reload();
} //END OF FUNCTION RELOADTABLE()

export function setTableEnabled(value) {
    hasTable = value
}

export function setMomentFormat(value) {
    momentFormat = value;
}

//METHODS GETTERS AND SETTERS
export function setRoutes(array) {
    routes = array
}

export function getRoutes() {
    return routes;
}

function checkCanMake() {
    if (operator == 'requester') {
        axios.get(routes.check).then(
            function (response) {
                if (response.data.status == 'success') {
                    canMake = response.data.data;
                } else if (response.data.status == 'warning') {
                    showSwal('warning', response.data.title, response.data.text);
                } else {
                    showSwal('error', response.data.title, response.data.text);
                }
            }); //END OF PROMISE'S THEN
    }
}
