<template>
  <div>
    <!--IF THERE ARE MORE THAN 1 PANEL, MAKE IT A NAV-TAB-->
    <div  v-if="tabs.length > 0">

        <b-card no-body>
            <b-tabs v-model="tabIndex" pills card vertical>
                <b-tab
                    v-for="item in tabs"
                    :key="item"
                    :title="(item == 'general') ? $t('app.common.general') : getLabel(item)"
                >
                    <b-card-text>
                         <slot :name="item"></slot>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
    <!-- OTHERWISE DO NOT CREATE THE NAV TAB AND JUST ADD THE COMPONENT TO THE MODAL-BODY -->
    <div v-else="">
      <div v-for="item in tabs" :id="item" class="modal-body" :key="item">
        <slot :name="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    util,
    isEmpty
  } from '../../mixins/Utils';
  import utility from '../../mixins/Utils';
import {randomPassword} from './../../../../public/js/functions'

  export default {
    name: "",
    data: () => ({
        tabIndex: 0
    }),
    props: [
      'header',
      'labels',
    ],
    mixins: [
      util,
      utility
    ],
    computed: {
      tabs: function(e) {
        let tabs = this.header.replace('[', '').replace(']', '').split(',');
        return tabs;
      }
    },
    methods: {
      getLabel(e) {
            if(typeof this.labels === "object"){
                return this.labels[e];
            } else {
                return JSON.parse(this.labels)[e];
            }
      }
    },
    created() {

    },
    mounted() {

    } //END OF MOUNTED
  } //END OF EXPORT DEFAULT
</script>

<style lang="scss" scoped>
  ul {
    margin-bottom: 5px!important;
  }
</style>
