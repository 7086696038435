import { isEmpty } from './Utils';


//METHOD THAT VALIDATE ALL ELEMENTS WHICH HAVE A NAME ATTRIBUTED INSIDE THE FORM PASSED BY PARAMS
export function validateForm(form){
  //GET ALL ELEMENT WHICH HAS A NAME ATTRIBUTED
  let fields = document.querySelectorAll(`${form} [aria-required="true"]`);
  fields = Array.prototype.slice.call(fields, 0);

  //CREATE AN ARRAY TO GRASP THE ELEMENTS THAT ARE VISIBLE
  let vfields = new Array();

  //LOOK THROUGHT ALL ARRAY
  for(let i = 0; i < fields.length; i++) {
    //GRAB THE ELEMENT
    let input = fields[i];
    //NOW VERIFY IF IT IS VISIBLE
    // if(input.offsetWidth > 0 && input.offsetHeight > 0){
      //IF IT IS, SO PUSH THIS ELEMENT TO THE ARRAY VFIELDS
      // vfields.push(input);

      validateElement(input);
    // } //END OF IF
  } //END OF FOR

  //FINALLY RETURN TRUE IF IT IS EVERYTHING FINE VALIDATED OR FALSE IF ANY VALIDATION HAS FAILED
  let isOk = fields.filter((component) => {
    return component.getAttribute('aria-invalid') == 'true';
  });


  //THEN RETURN IF VALIDATION FAILS OR NOT
  if(isOk.length > 0){
    return false;
  }else{
    return true;
  }
} //END OF FUNCTION VALIDATEFORM

export function validateElement(el){

  let vue = el.__vue__;

  //THE VALIDATION CANT BE DONE TO THE FIELDS INPUT HIDDEN ID, SO NOTHING CAN BE JUST ID
  if(el.getAttribute('name') != 'id'){
    if(!isEmpty(vue)){
      if(vue.$vnode.componentOptions.tag == 'v-select'){
        validateItVue(el, vue.$vnode, vue.mutableValue);
      }else{
        validateItVue(el, vue.$vnode, vue.value);
      }
    }else{
      validateItHTML(el);
    }
  } //END OF IF ID

} //END OF FUNCTION VALIDATEELEMENT(EL)

//METHOD THAT VALIDATE THE FIELS INPUT OR OTHER SO SIMPLE AS AN INPUT, IN OTHER WORDS PURE HTML COMPONENTS
export function validateItHTML(el){

  if(el.getAttribute('data-table') == "true"){

    if(el.matches('input')){

      setFeedbackValidation(el, 'table_input', 'success', '');

      if(el.value.length > 0){
        //DO NOTHING BECAUSE THE COLORS HAS ALREADY BEEN REMOVED
        el.setAttribute('aria-invalid', false);
      }else{
        if(el.getAttribute('aria-required') == 'true'){
          el.setAttribute('aria-invalid', true);
          setFeedbackValidation(el, 'table_input', 'warning', trans.getString("validation.required"));
        }
      } //ENDO OF  IF(EL.VALUE.LENGTH > 0)
    } //END OF IF(EL.MATCHES('INPUT'))
  }else{
    //REMOVE ALL FEEDBACK COLOR FROM THE COMPONENT OF GENERAL COMPONENT INPUT EMAIL ...
    removeClassFeedBackColors(el, 'input');

    if(isEmpty(el.value)){

      if(el.getAttribute('aria-required') == 'true'){
        //IF THE ELEMENT IS A FILE THE VALIDATION OCCURS DIFFERENTLY
        if(el.getAttribute('type') == 'file'){
          setFeedbackValidation(el, 'file', 'warning', trans.getString("validation.file"));
        }
        else{
          setFeedbackValidation(el, 'input', 'warning', trans.getString("validation.required"));
        }
      }else{
        //DO NOTHING BECAUSE THE COLORS HAS ALREADY BEEN REMOVED
      }

    }else{
      if(el.getAttribute('type') == 'email'){
        //CREATE THE EMAIL REGULAR EXPRESSION
        var email = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        //TEST IF THE EMAIL IS VALID OR NOT
        if(email.test(el.value)){ //IN CASE OF SUCCESS VALIDATE IT AND SHOW IT IN SUCCESS COLOR
          //SET THE COLOR
          setFeedbackValidation(el, 'email', 'success', '');
        }else{
          //SET THE COLOR
          setFeedbackValidation(el, 'email', 'danger', trans.getString('validation.email'));
        }
        //END OF IF EMAIL
      }else if(el.getAttribute('type') == 'password'){
        if(el.getAttribute('data-match') == 'confirmation'){
            let prefix = el.dataset.prefix;
            let password = document.querySelector('[data-match="password"][data-prefix="'+prefix+'"]');
          if(password.value == el.value){
            if(password.value.length > 0){
              if(password.getAttribute('aria-invalid') == 'true'){
                setFeedbackValidation(el, 'input', 'danger', 'The password is not valid!');
              }else{
                setFeedbackValidation(el, 'input', 'success', '')
              }
            }
          }else{
             setFeedbackValidation(el, 'input', 'danger', 'The password does not match, verify it!');
          }
        } else{
          let confirmation = document.querySelector('[data-match="confirmation"]');

          if(confirmation.value.length == 0){
            setFeedbackValidation(confirmation, 'input', 'warning', trans.getString("validation.required"));
          }
          if(el.value.length == 0){
            if(confirmation.value.length == 0){
              removeClassFeedBackColors(confirmation, 'input');
            }
          }else{
            if(el.value.length >5){
              if(confirmation.value.length > 0 && confirmation.value != el.value){
                setFeedbackValidation(confirmation, 'input', 'danger', 'The password does not match, verify it!');
                setFeedbackValidation(el, 'input', 'success', '');
              }else if(confirmation.value.length > 0 && confirmation.value == el.value){
                setFeedbackValidation(el, 'email', 'success', '');
                setFeedbackValidation(confirmation, 'email', 'success', '');
              }else{
                setFeedbackValidation(el, 'email', 'success', '');
              }

            }else{
              setFeedbackValidation(el, 'password', 'danger', 'The password must contain at least 6 chars');
              if(confirmation.value.length > 0 && confirmation.value != el.value){
                setFeedbackValidation(confirmation, 'input', 'danger', 'The password does not match, verify it!');
              }
            }
          }
        } //END OF IF PASSOWRD CONFIRMATION
      }else if(el.getAttribute('type') == 'file') {
        setFeedbackValidation(el, 'file', 'success', '');
      } else if(el.getAttribute('type') == 'number'){
        let maxValue = parseInt(el.getAttribute('max'));
        let minValue = parseInt(el.getAttribute('min'));
        let value = parseInt(el.value);
        let color;
        let message;


            if( value < minValue || value > maxValue ) {
                color = 'danger';
                message = trans.getString('validation.between.numeric');

                let attrs = {
                    'max': maxValue,
                    'min': minValue
                }

                //SET THE COLOR
                setFeedbackValidation(el, 'input', color, message, attrs);
            } else {
                color = 'success';
                message = '';

                //SET THE COLOR
                setFeedbackValidation(el, 'input', color, message);
            }
        } else{
        //FOR THE COMPONENTS THAT DOESN'T NEED ANY SPECIAL TRAITEMENT
        setFeedbackValidation(el, 'input', 'success', '');
      }
    } //END OF ELSE ISEMPTY
  }
} //END OF FUNCTION VALIDATEITHTML

//METHOD THAT VALIDATES THE COMPONENTS VUE JUST LIKE VUE-SELECT, VUE-TEL-INPUT, THE-MASK, DATATIMEPICKER...
export function validateItVue(el, vnode, value){
  //GRAPS WHICK KIND OF VUE COMPONENT IT IS
  let component = vnode.componentOptions.tag;

  //THE BUNDLE OF CODE BELOW IS USED TO ARRANGE THE PROBLEM WITH VUE-SELECT DIV
  let input;

  if(component == 'v-select' || component == 'vue-tel-input'){
    input = el.querySelector('input');
  }else{
    //IF IS NOT VUE-SELECT JUST TAKE THE SAME COMPONENT OF EL
    input = el;
  }

  try{
    if(el.getAttribute('data-table')=="true"){

      if(el.__vue__.$vnode.componentOptions.tag == 'v-select'){
        if(isEmpty(value.value)){
          setFeedbackValidation(input, 'table_select', 'warning', trans.getString("validation.required"));
        }else{
          //removeClassFeedBackColors(input, 'table_select');
          setFeedbackValidation(input, 'table_select', 'success', '');
          let dropdown = input.closest('.dropdown');
          dropdown.setAttribute('aria-invalid', false);
        }
      }else if (el.__vue__.$vnode.componentOptions.tag == 'vue-tel-input'){
        //TODO VALIDATION TO VUE-TEL-INPUT INSIDE TABLES
      }else if(el.__vue__.$vnode.componentOptions.tag == 'the-mask'){
        //TODO VALIDATION TO THE MASK INSIDE TABLES
      }else{
        //IF THE COMPONENT IS A MISC-TABLE-INPUT, THE MOST SIMPLIEST COMPONENT EXISTENT... MAKE THE VALIDATION BELOW
        if(isEmpty(input.value)){
          setFeedbackValidation(input, 'table_input', 'warning', trans.getString("validation.required"));
        }else{
          setFeedbackValidation(input, 'table_input', 'success', '');
          el.setAttribute('aria-invalid', false);
        }
      }

    }else{
      //IF VALUE IS NULL FEED BACK A WARNING TO THE USER
      if(isEmpty(value)){

        if(el.getAttribute('aria-required') == 'true'){
          setFeedbackValidation(input, component, 'warning', trans.getString("validation.required"));
        }else{
          removeClassFeedBackColors(el, component);
        }
      }else if(component == 'v-select'){
        //IF THE SELECTED ITEM HAS NO VALUE RETURN A WARNING
        // if(value.value == 0){
        //THIS IF 0 MUST BE DONE TO AVOID AN UNEXPECTED BEHAVIOR OF FUNCTION ISEMPTY RETURN TRUE FOR 0.
        //setFeedbackValidation(input, component, 'success', '');
        // }else{

        if(isEmpty(value)){
          setFeedbackValidation(input, component, 'warning', trans.getString("validation.required"));
        }else{
          //OTHERWISE FEED BACK A SUCCESS MESSAGE
          setFeedbackValidation(input, component, 'success', '');
        }
        // }
      }else if(component == 'the-mask'){
        //IF THE FIELD IS AN IDENITY_NUMBER DO THIS
        if(el.classList.contains('nin')){
          if(value.length == el.getAttribute('data-length')){
            //MAKE THE VALIDATION ON SERVER SIDE
            let routeLength;
            try{
              routeLength = auth.$routes.route('locale.valid_nin');
            }catch(e){
              routeLength = vue.$routes.route('locale.valid_nin');
            }
            axios.post( routeLength, {nin: value}).then(/*UPDATE ROUTE*/
              response => (
                (response.data == 1) ?
                setFeedbackValidation(input, component, 'success', '')
                :
                setFeedbackValidation(input, component, 'danger', 'The sequence doesn\'t match')
              ) //END OF ARROW'S FUNCTION
            ) //END OF PROMISE'S THEN
          } //END OF IF VALUE.LENGTH
        } //END OF IF CONTAINS NIN
        else{
          setFeedbackValidation(input, component, 'warning', "This field must be properly fullfilled");
          return;
        }
      }else if(component == 'vue-tel-input'){
        setTimeout(()=> {
          if(el.querySelector('input').getAttribute('state') == "true"){
            setFeedbackValidation(input, component, 'success', "");
          }else{
            setFeedbackValidation(input, component, 'danger', "This number doesn\'t match");
          }
        }, 50);
       }  else {
        //IF THE FIELD IS NOT EMPTY AND THERE IS NO MORE VALIDATION... MARK IT AS SUCCESSFULLY VALIDATED
        setFeedbackValidation(input, component, 'success', '');
      }
    }
  }catch(e){
    // console.log('EXCEPTION IN VALIDATE COMPONENT CAUGHT! '+e);
  }

} //END OF FUNCTION VALIDATEITVUE

export function setFeedbackValidation(el, component, color, message, attributes = {}){

    var label = $(el).closest('div.form-group').find('label').text();
    attributes['attribute'] = label;

    for (var key in attributes) {
        let oldV = '{' + key + '}';
            let newV = '<b>' + attributes[key] + '</b>';
            message = message.replace(oldV, newV);
    }

  if(component == 'table_select'){
    setTimeout(() => {
      let element = el.closest('[data-table]');

      removeClassFeedBackColors(element, component);

      if(color == 'success'){
        //DO NOTHING BECAUSE SUCCESS BACKGROUND IS TRANSPARENT WHICH IS THE PATTERN
      }else if(color == 'warning'){
        element.classList.add('tabled_warning');
      }else{
        element.classList.add('tabled_danger');
      }
    }, 200);

  }else if(component == 'table_tel'){

  }else if(component == 'table_mask'){

  }else if(component == 'table_input'){
    //REMOVE ALL VALIDATION FEED BACK
    removeClassFeedBackColors(el, component);

    //THEN SET THE NEW REPRESENTATION OF THE STATUS VALIDATION
    if(color == 'success'){
      //INSIDE A TABLE, A VALIDATE FIELD DOESN'T BECOME GREEN, IT JUST LOST ALL KIND OF FEEDBACK, AND IT HAS JUST BEEN DONE ...
    }else if(color == 'warning'){
      el.classList.add('tabled_warning');
    }else{
      el.classList.add('tabled_danger');
    }

  }else{
    //GRASP THE VALUE OF THE COMPONENT, IT WAS MADE TO BE EASIER TO MAINTAIN THE CODE
    let value = el.value;
    let group = el.closest('.form-group');
    let label = group.querySelector('label');
    let small = group.querySelector('small');
    let span = group.querySelector('.input-group-text');
    let inputGroup = group.querySelector('.input-group');

    //REMOVE ALL FEEDBACK COLOR FROM THE COMPONENT
    removeClassFeedBackColors(el, component);

    //FIRST THING, FIGURE OUT WHAT KIND OF COMPONENT IS THIS
    //IF IS A V-SELECT DO :
    if(component == 'v-select'){
      //GRASP THE DROPDOWN OF VUE-SELECT BECAUSE IT IS JUST LIKE THE INPUT FOR THE OTHER FIELDS, SO THE BORDER MUST BE PUT HERE
      let dropdown = el.closest('.dropdown-toggle');
      dropdown.classList.add(`border-${color}`);
      let div = dropdown.closest('.v-select');
      setTimeout(() => {

      if(color != 'success'){
        div.setAttribute('aria-invalid', true);
      }else{
        div.setAttribute('aria-invalid', false);
      }
      },300);
    }else if(component == 'vue-tel-input'){
      let div = el.closest('.vue-tel-input');
      div.classList.add(`border-${color}`);

      setTimeout(() => {
        if(color != 'success'){
          div.setAttribute('aria-invalid', true);
        }else{
          div.setAttribute('aria-invalid', false);
        }
      },150);
    } else if(component == 'file'){
      //IF THE COMPONENT IS A INPUT FILE THEN GET THE INPUT TEXT SHOWN TO THE USER
      let inputShown = group.querySelector('[type="text"]');
      let fileButton = group.querySelector('button');

      //ADD THE PROPER COLORS
      inputShown.classList.add(`border-${color}`);
      fileButton.classList.add(`border-${color}`, `bg-${color}`, `input-${color}`);
    }else{
      //FOR THE REST OF THE COMPONENT JUST PUT THE BORDER ON THE INPUT
      el.classList.add(`border-${color}`)

      if(color != 'success'){
        el.setAttribute('aria-invalid', true);
      }else{
        el.setAttribute('aria-invalid', false);
      }
    }
    //CHANGE THE HTML WHICH IS SIMILAR TO ALL COMPONENTS
    label.classList.add(`text-${color}`);
    span.classList.add(`bg-${color}`);
    group.classList.add(`text-${color}`);
    inputGroup.classList.add(`input-${color}`);

    //AND FINALLY JUST CHANGE THE INNERHTML FROM THE SMALL TO FEEDBACK THE USER
    small.innerHTML = message;
  } //END OF KIND OF COMPONENTS TABLED OR NOT

   //MAKE THE VALIDATION IN WIZARD, SAVEBUTTON, AND RESPONSIVETABLES
   validationInside.methods.validateWizTab(el);
   validationInside.methods.validateSaveButton(el);

   validationInside.methods.validateDynamicTable();

} //END OF FUNCTION SETFEEDBACKVALIDATION

export const validationInside = {
  methods: {
    validateWizTab(el){
      //NOW IF THE PANEL IS A WIZARDTAB, MANIPULATE THE BUTTON NEXT ACCORDING WITH THE COMPONENTS WHICH ARE VISIBLE
      let panel = el.closest('[data-panel="wizard"]');

      //IF THE WIZARD EXISTS... VALIDATE IT
      if(!isEmpty(panel)){
        setTimeout(() => {
          //IF ALL REQUIRED FIELDS OF THIS TAB ARE FULFILLED DO IT.
          let fields = document.querySelectorAll(`${document.querySelector('#nav-wiz .active').getAttribute('href')} [aria-invalid="true"]`);

          if(fields.length == 0){
            let buttonNext = document.getElementById('wizNext');
            let buttonPrev = document.getElementById('wizPrev');

            buttonNext.classList.remove('btn-outline-primary');
            buttonNext.classList.add('btn-primary');
            buttonNext.querySelector('svg').classList.add('faa-pulse', 'animated');
            buttonPrev.classList.remove('btn-outline-primary');
            buttonPrev.classList.add('btn-primary');
            buttonPrev.querySelector('svg').classList.add('faa-pulse', 'animated');

          }else{
            let buttonNext = document.getElementById('wizNext');
            let buttonPrev = document.getElementById('wizPrev');

            buttonNext.classList.remove('btn-primary');
            buttonNext.querySelector('svg').classList.remove('faa-pulse', 'animated');
            buttonNext.classList.add('btn-outline-primary');
            buttonPrev.classList.remove('btn-primary');
            buttonPrev.querySelector('svg').classList.remove('faa-pulse', 'animated');
            buttonPrev.classList.add('btn-outline-primary');
          }
        }, 350);
      } //END OF IF(!ISEMPTY(PANEL))
    }, //END OF FUNCTION VALIDATEWIZTAB()
    validateSaveButton(el){
      setTimeout(()=> {
        let fields = el.closest('form').querySelectorAll('[aria-invalid="true"]');

        //IF THERE IS NO INVALID FIELD, CHANGE THE COLOR OF THE BUTTON
        let saveButton = el.closest('form').querySelector('.save');
        if(saveButton != null){
            if(fields.length == 0){
                saveButton.classList.add('btn-primary');
                saveButton.classList.remove('btn-outline-primary');
            }else{
                saveButton.classList.add('btn-outline-primary');
                saveButton.classList.remove('btn-primary');
            }
        }
     }, 250);

    },
    validateDynamicTable(){
      let components = document.querySelectorAll('[data-validate="true"]');

      components.forEach((comp) => {
        if(comp.matches('table')){
          //IF THE TABLE IS EMPTY, IN OTHER WORDS, HAS JUST THE TR FROM THEAD...
          if(comp.rows.length == 1){
            comp.setAttribute('aria-invalid', true);
          }else if(comp.rows.length > 1){
            //IF THE TABLE IS NOT EMPTY, SET INVALID AS FALSE
            comp.setAttribute('aria-invalid', false);

          }
        } //END OF IF(COMP.MATCHES('TABLE'))
      }); //END OF COMPONENTS.FOREACH
    } //END OF VALIDATEDYNAMICTABLE
  } //END OF METHODS
} //END OF EXPORT CONST VALIDATIONINSIDE

export function removeClassFeedBackColors(el, component){
  //VERIFY IF IT IS A TABLE COMPONENT OR NOT
  if(component == 'table_select'){
    let dropdown = el.closest('.dropdown');
    //AND B-U-G RISES UP WITH VUE-SELECT SO THE IF BELOW RESOLVE THE PROBLEM OF MULTIPLE RECURSION OF THIS FUNCTION.
    if(dropdown != null){
      setTimeout(() => {
        dropdown.classList.remove('tabled_warning', 'tabled_danger', 'tabled_success');
        dropdown.querySelector('input').classList.remove('tabled_warning', 'tabled_danger', 'tabled_success');
      },
      250);
    }

  }else if(component == 'table_tel'){

  }else if(component == 'table_mask'){

  }else if(component == 'table_input'){
    el.classList.remove('tabled_warning', 'tabled_danger', 'tabled_success');
  }else{
    //**** */IF THE ELEMENT IS A TABLE JUST AVOID IT BECAUSE TABLES DONT HAVE FEEDBACK, YET...*****
    if(el.matches('table')){
      return null;
    }
    //GRASP THE VALUE OF THE COMPONENT, IT WAS MADE TO BE EASIER TO MAINTAIN THE CODE
    let value = el.value;
    let group = el.closest('.form-group');
    let label = group.querySelector('label');
    let small = group.querySelector('small');
    let span = group.querySelector('.input-group-text');
    let inputGroup = group.querySelector('.input-group');

    //VERIFY WHICH KIND OF COMPONENT IT IS
    if(component == 'v-select'){

      let dropdown = el.closest('.input-group').querySelector('.dropdown-toggle');

      //AND B-U-G RISES UP WITH VUE-SELECT SO THE IF BELOW RESOLVE THE PROBLEM OF MULTIPLE RECURSION OF THIS FUNCTION.
      if(dropdown != null){
        dropdown.classList.remove('border-success', 'border-warning', 'border-danger');

      }

    }else if(component == 'vue-tel-input'){
      let div = el.closest('.vue-tel-input');
      div.classList.remove('border-success', 'border-warning', 'border-danger');
      label.classList.remove('text-success', 'text-warning', 'text-danger');
      span.classList.remove('bg-success', 'bg-warning', 'bg-danger');
      group.classList.remove('text-success', 'text-warning', 'text-danger');
      inputGroup.classList.remove('input-success', 'input-warning', 'input-danger');
      return;
    }else if(component == 'file'){
      //IF THE COMPONENT IS A INPUT FILE THEN GET THE INPUT TEXT SHOWN TO THE USER
      let inputShown = group.querySelector('[type="text"]');
      let fileButton = group.querySelector('button');

      //ADD THE PROPER COLORS
      inputShown.classList.remove(`border-success`, `border-warning`, `border-danger`);
      fileButton.classList.remove(`border-success`, `bg-success`, `input-success`,
      `border-warning`, `bg-warning`, `input-warning`, `border-danger`, `bg-danger`, `input-danger`);

    }else{ //ALL OTHER COMPONENTS THAT HAS THE SAME STRUCTURE OF AN INPUT
      el.classList.remove('border-success', 'border-warning', 'border-danger');
    }

    label.classList.remove('text-success', 'text-warning', 'text-danger');
    span.classList.remove('bg-success', 'bg-warning', 'bg-danger');
    group.classList.remove('text-success', 'text-warning', 'text-danger');
    inputGroup.classList.remove('input-success', 'input-warning', 'input-danger');
    small.innerHTML = "";
    }

} //END OF REMOVECLASSFEEDBACKCOLORS

//FUNCTION THAT FORCE THE FILES VUE-TEL-INPUT TO REMOVE ITS MASK BEFORE SAVE IN THE DATABASE
export function removeINTLMask(string){
  string = string.replace('(', '');
  string = string.replace(')', '');
  string = string.replace('-', '');
  string = string.replace(' ', '');

  return string;
}
