<template>
  <div class="form-group">
    <label for="functions">{{ $t(`label.${this.label}`) }}</label>
    <div class="input-group">
        <v-select :name="named" ref="name" @input="$emit('changed', value)" :filterable="false" :options="options"  v-validate="validate" v-feedback="feedback" class="border-default" v-model="value"  @search="onSearch"  @search:focus="onSearch"
        :select-on-tab="true" >
            <div slot="no-options">{{ $t(`placeholder.label`) }}</div>
        </v-select>
      <div class="input-group-append">
        <span class="input-group-text bg-default"  data-toggle="popover" data-container="body" data-placement="bottom" :data-content="tooltiped">
          <i :class="icon + ' fa-inverse'"></i>
        </span>
      </div>
    </div>

    <small v-if="small != 'false'"></small>
  </div>
</template>
<script>
import { toUpperCamelCase, toLowerCamelCase, isEmpty } from '../../mixins/Utils';

export default {
  data: () => ({
    invalid: 'false',
    options: []
  }),
  props: [
    'label',
    'name',
    'route',
    'prefix',
    'suffix',
    'feedback',
    'validate',
    'icon',
    'val',
    'tooltip',
    'small',
    'startInvalid',
    'fixedOptions'
  ],
  methods: {
     onSearch(search, loading) {
        search = search || this.$refs.name.search;
        loading = loading || this.$refs.name.toggleLoading;

        loading(true);
        this.search(loading, search, this);
    },
    search: _.debounce((loading, search, vm) => {
        axios.get(vm.route +'?term='+search).then((response) => {
        vm.options = response.data;
        loading(false);
      });
    }, 350)
  }, //END OF METHODS
  computed: {
    //TRAIT THE ARRAY CAME FROM BACK-END
    optionsTraited(){
      //REMOVE THE OPTION WHICH ARE ALREADY SELECTED
      return this.options;
    }, //END OF OPTIONSTRAITED
    named() {
      return this.name;
    },
    tooltiped(){
      return (this.tooltip) ? trans.$t(`tooltip.${this.tooltip}`) : $('tooltip.generic_select');
    },
    value: {
      get(){
        if(this.prefix.indexOf('.') != -1){
          let prefixes = this.prefix.split('.');
          return this.$store.state[prefixes[0]][prefixes[1]][this.suffix];
        }
        return this.$store.state[this.prefix][this.suffix];
      },
      set(value){
        this.$store.commit(toLowerCamelCase(this.prefix) + toUpperCamelCase(this.suffix), value);
      }
    } //END OF VALUE
  }, //END OF COMPUTED
  mounted() {
    //IF A START VALUE HAS BEEN SET, ATTRIBUTE THE VALUE OF VAL TO THE VALUE V-MODEL
    if(this.val){
      this.value = this.val;
    }

    //FORCE THE COMPONENT STARTS WITH INVALID. IT HELPS THE VALIDATION AT THE FIST TIME
    setTimeout(
      () => {
        if(this.startInvalid == 'false'){

        }else{
          document.querySelector(`[name="${this.named}"]`).setAttribute('aria-invalid', true);
        }
      }
    , 500);
  } //END OF MOUNTED
} //END OF EXPORT DEFAULT
</script>
