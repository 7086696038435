<template>
  <div class="form-group">
    <label :for="label">{{ this.label }}</label>
    <div class="input-group">
      <v-select :name="named" v-validate="validate" v-feedback="feedback" class="border-default" v-model="value" :options="options" :select-on-tab="true"></v-select>
      <div class="input-group-append">
        <span class="input-group-text bg-default"  data-toggle="popover" data-container="body" data-placement="bottom" :data-content="tooltiped">
          <i :class="icon + ' fa-inverse'"></i>
        </span>
      </div>
    </div>
    <small></small>
  </div>
</template>
<script>
import { toUpperCamelCase, toLowerCamelCase, isEmpty } from '../../mixins/Utils';

export default {
  data: () => ({

  }),
  props: [
    'label',
    'name',
    'prefix',
    'suffix',
    'options',
    'validate',
    'feedback',
    'icon',
    'tooltip',
    'disableThis'
  ],
  computed: {
    named() {
      return this.name;
    },
    value: {
      get: function(){
        if(this.prefix.indexOf('.') != -1){
          let prefixes = this.prefix.split('.');
          return this.$store.state[prefixes[0]][prefixes[1]][this.suffix];
        }

        return this.$store.state[this.prefix][this.suffix];
      },
      set: function(value){
        this.$store.commit(`${toLowerCamelCase(this.prefix)}${toUpperCamelCase(this.suffix)}`, value);
      }
    }, //END OF VALUE
    tooltiped(){
      return (this.tooltip) ? trans.$t(`tooltip.${this.tooltip}`) : $('tooltip.generic_select');
    }
  }, //END OF COMPUTED
  mounted(){
    setTimeout(()=> {if(this.validate){document.getElementsByName(this.named)[0].setAttribute('aria-invalid', true)}}, 400);
  }
} //END OF EXPORT DEFAULT
</script>
