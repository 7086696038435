<template>
  <div class="form-group">
    <label :for="label">{{ this.label }}</label>
    <div class="input-group">
      <input :type="(type) ? type : 'text'" v-model="value" @input="$emit('changed', value)" v-feedback="(feedback) ? feedback : false" :v-validate="(validate) ? validate : ''" class="form-control border-default" :name="named" v-camelcase="camelcase" v-lowercase="lowercase" maxlength="255"/>
      <div class="input-group-append">
        <!-- <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="$t(tooltiped)"> -->
        <span class="input-group-text bg-default">
          <i :class="icon + ' fa-inverse'"></i>
        </span>
      </div>
    </div>
    <small v-if="small != 'false'"></small>
  </div>
</template>
<script>
import { toUpperCamelCase, toLowerCamelCase, isEmpty } from '../../mixins/Utils';

export default {
  props: [
    'label',
    'type',
    'name',
    'camelcase',
    'lowercase',
    'tooltip',
    'prefix',
    'suffix',
    'feedback',
    'validate',
    'icon',
    'small'
  ],
  computed: {
    named: function(){
      return (this.name) ? this.name : 'input';
    },
    tooltiped: function(){
      return (this.tooltip) ? `tooltip.${this.tooltip}` : 'tooltip.name';
    },
    value: {
      get: function(){

        if(this.prefix.indexOf('.') != -1){
          let prefixes = this.prefix.split('.');
          return this.$store.state[prefixes[0]][prefixes[1]][this.suffix];
        }

        return this.$store.state[this.prefix][this.suffix];
      },
      set: function(value){
        this.$store.commit(`${toLowerCamelCase(this.prefix)}${toUpperCamelCase(this.suffix)}`, value);
      }
    } //END OF VALUE
  }, //END OF COMPUTED
  mounted() {
    //WORAROUND TO AVOID THE PROBLEM OF VEE-VALIDATION DO NOT CREATE THE ATTR ARIA-REQUIRED BY PROPS
    setTimeout(
      () => {

        if(this.validate == "required"){
          document.getElementsByName(this.named)[0].setAttribute('aria-invalid', true);
          document.getElementsByName(this.named)[0].setAttribute('aria-required', true);
        }
      } //END OF ARROW FUNCTION
      , 300);
    }//END OF MOUNTED

  } //END OF EXPORT DEFAULT
  </script>
