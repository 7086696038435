/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import _ from 'lodash';

// window.Vue = require('vue');

import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex);

import initialState from 'vuex';

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import vSelect from 'vue-select';

import VeeValidate from 'vee-validate';

//OFR USING LARAVEL INTERNATIONALIZATION
import VueInternationalization from 'vue-i18n';
import Locale from './vue-i18n-locales.generated';


// CRSF Token in Axios
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
};

//CODE THAT IMPORTS THE LARAVEL LOCALIZATION
Vue.use(VueInternationalization);
const lang = document.documentElement.lang;
// OR HOWEVER YOU DETERMINE YOUR CURRENT APP LOCALE
const i18n = new VueInternationalization({
  locale: lang,
  messages: Locale
});

//VUE-LAROUTE USED TO GET THE ROUTES FROM LARAVEL
import VueLaroute from 'vue-laroute';
import routes from '../../public/js/laroute.js';

Vue.use(VueLaroute, {
  routes,
  accessor: '$routes', // Optional: the global variable for accessing the router
});

//INSTANCE OF VEE-VALIDATE
Vue.use(VeeValidate,{
    events: 'input|focusout'
});

//CODE USED TO BE ABLE TO CALL THE TRANSLATE OF LARAVEL FROM JS FILES
//CODE USED TO BE ABLE TO CALL THE TRANSLATE OF LARAVEL FROM JS FILES
window.trans = new Vue({
    i18n,
    methods: {
      getString(string) {
          return this.$t(string);
      },
      getChoice(string, choice) {
          return this.$tc(string, choice);
      }
    } //END OF METHODS
  });

function isEmpty(value){
    if(value == undefined || value == '' || value ==null){
      return true
    }
    return false;
}

import { validateItHTML, validateItVue } from './mixins/Validation';

Vue.directive('feedback', {
  bind: function (el, binding, vnode) {
    if(isEmpty(vnode.componentInstance)){
      //BIND THE EVENT TO THE HTML ELEMENT
      el.addEventListener('focusout', function(e) {
        validateItHTML(el);

      }); //END OF THE EVENT LISTENER
    }
    else {
      //IF THE COMPONENT HAS THE ATTRIBUTE COMPONENTINSTANCE (VUE-COMPONENTS) LOOK IF IT IS EMPTY
      vnode.componentInstance.$on('input', function (value) {
        validateItVue(el, vnode, value);

      });
    } //END OF ELSE IF(ISEMPTY(VNODE.COMPONENTINSTANCE))
  } //END OF HOOK BIND
}); //END OF THE DIRECTIVE FEEDBACK

Vue.directive('lowercase', {
    bind: function (el, binding, vnode) {
      if(binding.value == 'true'){
        if(isEmpty(vnode.componentInstance)){
          //BIND THE EVENT TO THE HTML ELEMENT
          el.addEventListener('focusout', function(e) {
            el.value = el.value.toLowerCase();
            el.dispatchEvent(new Event('input'))
          }); //END OF THE EVENT LISTENER
        } // END OF IF   IF(ISEMPTY(VNODE.COMPONENTINSTANCE))
      } //END OF IF BINDING VALUE
    } //END OF BIND
  }); //END OF THE DIRECTIVE FEEDBACK

Vue.directive('camelcase', {
  bind: function (el, binding, vnode) {
    if(binding.value == 'true'){
      if(isEmpty(vnode.componentInstance)){
        //BIND THE EVENT TO THE HTML ELEMENT
        el.addEventListener('focusout', function(e) {
          transformCamelCase(el);

        }); //END OF THE EVENT LISTENER
      } // END OF IF   IF(ISEMPTY(VNODE.COMPONENTINSTANCE))
    } //END OF IF BINDING VALUE
  } //END OF BIND
}); //END OF THE DIRECTIVE FEEDBACK

function transformCamelCase(el){
  let text = axios.post(routes.route('capitalized_name'), {
    name: el.value
  }).then(response => {el.value = response.data; el.dispatchEvent(new Event('input'))});

}//END OF FUNCTION TRANSFORMCAMELCASE




/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('v-select', vSelect)

let files = require.context('./auth/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

files = require.context('./components/Miscellaneous', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

files = require.context('./components/Auths', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/***************************************LOGIN'S COMPONENTS********************************************/
// Vue.component('auth-username', require('./components/Auths/AuthUsername.vue').default);
// Vue.component('auth-password', require('./components/Auths/AuthPassword.vue').default);

/***************************************MISCELLANEOUS'S COMPONENTS********************************************/
// Vue.component('misc-input', require('./components/Miscellaneous/MiscInput.vue').default);
Vue.component('user-password', require('./components/Users/UserPassword.vue').default);

window.store = new Vuex.Store();
Vue.prototype.$store = store;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//INITIALIZES THE VUE APPLICATION
window.auth = new Vue({
    el: '#auth',
    store,
    i18n,
    methods: {
      isEmpty(thing) {
        return true;
      }
    }
});
