<template>
    <div>
      <form :action="route" method="post" id="form" @submit.prevent="onSubmit">
        <input type="hidden" name="_token" :value="csrf">

        <div class="row">
          <div class="col-md-12">
            <!-- <auth-username v-if="anchor == 'username'" @changed="username=$event" name="anchor" tooltip="password_forgot_username"></auth-username> -->
            <misc-input type="text" v-if="anchor == 'username'" prefix="passwordForgot" suffix="username" :label="$t('app.users.username')" @changed="username=$event" name="anchor" icon="fas fa-user" tooltip="password_forgot_username" validate="required" feedback="true"></misc-input>
            <!-- <misc-email v-if="anchor == 'email'" @changed="email=$event" name="anchor" tooltip="password_forgot_email" feedback="true"></misc-email> -->
            <misc-input type="email" :label="$t('app.users.email')" v-if="anchor == 'email'" prefix="passwordForgot" suffix="email" @changed="email=$event" name="anchor" icon="fas fa-envelope" tooltip="password_forgot_email" validate="required" feedback="true"></misc-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <button type="button" @click="backToLogin()" class="btn btn-outline-dark btn-block margin_bottom" >{{$t('buttons.back_to_login') }}</button>
          </div>
          <div class="col-md-6">
            <button type="submit" class="btn btn-primary margin_bottom btn-block">{{ $t('buttons.submit_reset') }}</button>

          </div>
        </div>

      </form>
    </div>
</template>

<script>
import {validateForm} from '../../mixins/Validation';
import { setTableEnabled } from '../../mixins/DataTables';

const passwordForgot = {
    state : {
        username: '',
        email: ''
    },
    mutations: {
        resetPasswordForgotState(state) {
            state.username = '';
            state.email = '';
        },
        passwordForgotUsername(state, value) {
            state.username = value;
        },
        passwordForgotEmail(state, value) {
            state.email = value;
        }
    }
}

export default {
    data: () => ({
    }),
    props: [
        'route',
        'csrf',
        'forgot',
        'login',
        'anchor'
    ],
    mounted() {
        setTableEnabled(0);
    },
    created() {
        this.$store.registerModule("passwordForgot", passwordForgot);
    },
    computed: {
        email() {
            return this.$store.state.email;
        },
        username() {
            return this.$store.state.username;
        },
    },
    methods: {
        onSubmit() {
            let isOk = validateForm('#form');

            if (isOk) {
                $('#loader').modal('show');
                document.getElementById("form").submit();
            }
        },
        backToLogin() {
            location.href = this.$props.login
        },
    }
} //END OF EXPORT DEFAULT
</script>
