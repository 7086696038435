<template>
  <div class="form-group">
    <label for="username">{{ $t('app.users.username') }}</label>
    <div class="input-group">
      <input type="text" v-model="value" v-validate="'required'" v-feedback="true" @input="$emit('changed', value)" class="form-control border-default" :name="(name) ? name : 'username'" maxlength="255"/>
      <div class="input-group-append">
        <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="(tooltip) ? $t(`tooltip.${tooltip}`) : $t('tooltip.login_username')">
          <i class="fas fa-user fa-inverse"></i>
        </span>
      </div>
    </div>
    <small></small>
  </div>
</template>
<script>
export default {
  data: () => ({
    value: ''
  }),
  props: [
    'tooltip',
    'name'
  ]
} //END OF EXPORT DEFAULT
  </script>
