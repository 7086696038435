<template>
     <div class="row">
        <div class="col-12">
            <button type="button" @click="changeDates('last-year')"
                class="btn btn-secondary mr-auto">
                {{ $t('buttons.last-year') }}
            </button>
            <button type="button" @click="changeDates('last-month')"
                class="btn btn-secondary mr-auto">
                {{ $t('buttons.last-month') }}
            </button>
            <button type="button" @click="changeDates('yesterday')"
                class="btn btn-secondary mr-auto">
                {{ $t('buttons.yesterday') }}
            </button>
            <button type="button" @click="changeDates('today')"
                class="btn btn-secondary mr-auto">
                {{ $t('buttons.today') }}
            </button>
            <button type="button" @click="changeDates('month')"
                class="btn btn-secondary mr-auto">
                {{ $t('buttons.month') }}
            </button>
            <button type="button" @click="changeDates('year')"
                class="btn btn-secondary mr-auto">
                {{ $t('buttons.year') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'prefix',
        'format'
    ],
    methods: {
        changeDates(value){
            let $start, $end;
            if(value == 'month'){
                $start = moment().startOf('month').format(this.format);
                $end = moment().endOf('month').format(this.format);
            } else if(value == 'last-month'){
                $start = moment().subtract(1,'months').startOf('month').format(this.format);
                $end = moment().subtract(1,'months').endOf('month').format(this.format);
            } else if (value == 'year'){
                $start = moment().startOf('year').format(this.format);
                $end = moment().endOf('year').format(this.format);
            } else if (value == 'last-year'){
                $start = moment().subtract(1,'years').startOf('year').format(this.format);
                $end = moment().subtract(1,'years').endOf('year').format(this.format);
            } else if (value == 'yesterday') {
                $start = moment().subtract(1,'days').format(this.format);
                $end = moment().subtract(1,'days').format(this.format);
            } else {
                $start = moment().format(this.format);
                $end = moment().format(this.format);
            }
            this.$store.commit(this.prefix + "DateBegin", $start);
            this.$store.commit(this.prefix + "DateEnd", $end);
        }
    }
}
</script>
