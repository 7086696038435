<template>
  <input type="typed" :aria-required="(validate == 'required') ? true: false"  :aria-invalid="(validate == 'required') ? true: false"
   v-feedback="true" data-table="true" @input="$emit('changed', value)" id="temp"
   :class="`border-0 w-100 text-center bg-transparentx ${(validate =='required') ? 'tabled_warning' : ''}`" :name="named" v-model="value"
   :readonly="(readonly == 'true') ? true : false"
   :min="(typed == 'number' && min) ? min : null"
   :max="(typed == 'number' && max) ? max : null"
   :step="(typed == 'number') ? 1 : null" />
</template>
<script>
export default {
  data: () => ({
    value: ""
  }),
  props: [
    'type',
    'name',
    'min',
    'readonly',
    'validate',
    'val'
  ],
  computed:{
    named(){
      return this.name;
    },
    typed(){
      return this.type;
    }
  },
  methods: {
    grabNextFocus(){
      let container = document.querySelector(`[name="${this.named}"]`).closest('table');
      let nextField = container.querySelector('input');
    }
  },
  mounted() {
    //GRASP THE ELEMENT WHICH HAS THE ID "T E M P"
    let field =document.getElementById('temp');
    //THEN VERIFY IF THE PROPS MIN EXISTS
    if(this.min){
      field.setAttribute('min', 1);

      //AND OBVIOUSLY ADD THE BS4 TO MAKE TEXT CENTRALIZED
      field.classList.add('text-center');
    }

    //IF A START VALUE HAS BEEN SET, ATTRIBUTE THE VALUE OF VAL TO THE VALUE V-MODEL
    if(this.val){
      this.value = this.val;
    }

    //FINALLY REMOVE THE ID FROM THE INPUT
    field.removeAttribute('id');
  } //END OF MOUNTED
} //END OF EXPORT DEFAULT
</script>
