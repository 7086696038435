<template>
  <div class="row">
    <div class="form-group col-md-6 col-sm-12">
    <label :for="named">{{ $t('app.common.date_begin') }}</label>
    <div class="input-group">
      <date-picker v-model="beginValue" v-validate="validate" @input="setDateMin()" class="border-default" v-feedback="feedback" :name="named + '_begin'" :config="optionsBegin"></date-picker>
      <div class="input-group-append">
        <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="(tooltip) ? $t(`tooltip.${tooltip}_begin`) : $t('tooltip.date')">
          <i :class="`${(this.icon) ? this.icon : 'fa fa-calendar-check'} fa-inverse`"></i>
        </span>
      </div>
    </div>
    <small></small>
  </div>
  <div class="form-group col-md-6 col-sm-12">
    <label :for="named">{{ $t('app.common.date_end') }}</label>
    <div class="input-group">
      <date-picker v-model="endValue" @input="setDateMax()" class="border-default" v-feedback="feedback" :name="named + '_end'" :config="optionsEnd"></date-picker>
      <div class="input-group-append">
        <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="(tooltip) ? $t(`tooltip.${tooltip}_end`) : $t('tooltip.date')">
          <i :class="`${(this.icon) ? this.icon : 'fas fa-calendar-times'} fa-inverse`"></i>
        </span>
      </div>
    </div>
    <small></small>
  </div>
  </div>
</template>

<script>
import { toUpperCamelCase, toLowerCamelCase, isEmpty, dateFormat } from '../../mixins/Utils';
export default {
  data: function(){
    var start = new Date();
    start.setHours(0,0,0,0);
    return {
      optionsBegin: {
          locale: (this.locale) ? this.locale : 'en',
        format: (this.format) ? this.format : 'L',
        useCurrent: false,
        defaultDate: start,
        // minDate: (this.past) ? undefined : moment(),
        // maxDate: moment(this.format, '01/29/2019')
      }, //END OF OPTIONS
      optionsEnd: {
          locale: (this.locale) ? this.locale : 'en',
        format: (this.format) ? this.format : 'L',
        useCurrent: false,
        defaultDate: start
        // minDate: moment()
      }, //END OF OPTIONS
    } //END OF RETURN
  }, //END OF DATA: FUNCTION
  props: [
    'label',
    'name',
    'feedback',
    'validate',
    'format',
    'prefix',
    'suffix',
    'icon',
    'tooltip',
    'past',
    'locale'
  ],
  computed: {
    named: function(){
      return (this.name) ? this.name : 'date';
    },
    beginValue:{
      get(){
        if(this.prefix.indexOf('.') != -1){

          let prefixes = this.prefix.split('.');
          return this.$store.state[prefixes[0]][prefixes[1]][this.suffix + 'Begin'];
        }

        return this.$store.state[this.prefix][this.suffix + 'Begin'];
      },
      set(value){
        this.$store.commit(`${toLowerCamelCase(this.prefix)}${toUpperCamelCase(this.suffix + 'Begin')}`, value);
      }
    },
    endValue:{
      get(){
        if(this.prefix.indexOf('.') != -1){

          let prefixes = this.prefix.split('.');
          return this.$store.state[prefixes[0]][prefixes[1]][this.suffix + 'End'];
        }

        return this.$store.state[this.prefix][this.suffix + 'End'];
      },
      set(value){
        this.$store.commit(`${toLowerCamelCase(this.prefix)}${toUpperCamelCase(this.suffix + 'End')}`, value);
        // setTimeout(() => {
        //   this.$store.commit(`${this.prefix}${toUpperCamelCase(this.suffix)}`, value);
        // }, 300);
      }
    }
  },
  methods: {
    setDateMin(){
      if(!isEmpty(this.beginValue)){
        this.optionsEnd.minDate = moment(this.beginValue, this.format);
      }
    },
    setDateMax(){
    },
  },
  mounted() {
    setTimeout(()=> {if(this.validate){document.getElementsByName(this.named + '_begin')[0].setAttribute('aria-invalid', true)}}, 400);
  }
} //END OG EXPORT DEFAULT

</script>
