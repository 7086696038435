<template>
  <div class="checkbox icheck-primary ">
    <input type="checkbox" :id="forCheck" :name="named" :value="forCheck" v-model="value"/>
    <label :for="forCheck" >{{ label }}</label>
  </div>
</template>
<script>
import { toUpperCamelCase, isEmpty } from '../../mixins/Utils';

export default {
  data: ()=>({
  }),
  props: [
    'label',
    'forCheck',
    'name',
    'prefix',
    'suffix',
    'feedback',
    'validate',
  ],
  computed: {
    named: function(){
      return (this.name) ? this.name : 'input';
    },
    value: {
      get: function(){
        return this.$store.state[this.prefix][this.suffix];
      },
      set: function(value){

        this.$store.commit(`${this.prefix}${toUpperCamelCase(this.suffix)}`, value);
      }
    } //END OF VALUE
  } //END OF COMPUTED
} //END OF EXPORT DEFAULT
</script>
<style lang="scss" scoped>
label{
  // margin-left: 5px!important;
  font-weight: normal!important;
}
</style>
