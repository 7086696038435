var switchButton = '<div class="form-check checkbox-slider-md checkbox-slider--b-flat checkbox-slider-danger"> \
<label> \
<input class="form-check-input" type="checkbox" id="toggleDelete" onchange="toggleDelete(this)" disabled=true> \
<span> \
<i class="fa fa-trash" style="font-size: 20px; margin-top: 5px"></i> \
</span> \
</label> \
</div>';

var swalIconError = '<span style="font-size: 80px"><i class="far fa-times-circle faa-flash animated"></i></span>';

var swalIconQuestion = '<span class="fa-layers" style="font-size: 80px"> \
<i class="far fa-circle faa-burst animated"></i> \
<i class="fas fa-question faa-pulse animated " data-fa-transform="shrink-7"></i> \
</span>';

var swalIconSuccess = '<span class="fa-layers" style="font-size: 80px"> \
<i class="far fa-circle faa-burst animated"></i> \
<i class="fas fa-check faa-float animated " data-fa-transform="shrink-7"></i> \
</span>';

var swalIconWarning =  '<span class="fa-layers" style="font-size: 80px"> \
<i class="far fa-circle faa-burst animated"></i> \
<i class="fas fa-exclamation faa-ring animated " data-fa-transform="shrink-7"></i> \
</span>';

//FUNCTION THAT SHOW THE STATIC'S SWALL LIKE SUCCESS AND ERROR, THIS IS USEFUL WHEN NO FUNCTION MUST BE EXCECUTED
export function showSwal(type, title = "", text = "") {

  //IF IT IS A SUCCESS SWAL DO IT
  if(type == 'success'){
    var promise = Swal.fire({ //CALL SWEETMODAL
      title: title,
      html: text,
      type: type,
      showConfirmButton: true,
      confirmButtonColor: '#28a745',
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      onOpen: function(){
        // $('.swal2-success').addClass('swal-icon-success');
        // $('.swal2-success').html(swalIconSuccess);
        $('.swal2-confirm').css('box-shadow','4px 5px 15px -5px green');
        $('.swal2-cancel').css('box-shadow','4px 5px 15px -5px gray');
      }, //END OF ONOPEN: FUNCTION()
      onClose: function(){
        //ENABLE THE PRIMARY BUTTON WHEN THIS SWAL CLOSE
        $('[data-effect="primary_button"]').removeAttr('disabled');
      }
    }); //END OF PROMISE
  }else if(type == 'warning'){// THERE IS THIS DIVISION IN CASE OF SETTING SUCCESS WITHOUT BUTTON AND WITH TIMER
    Swal.fire({ //CALL SWEETMODAL
      title: title,
      html: text,
      type: type,
      showConfirmButton: true,
      confirmButtonColor: '#ffc107',
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      onOpen: function(){
        $('.swal2-warning').addClass('faa-pulse animated swal-icon-warning');
        // $('.swal2-warning').addClass('swal-icon-warning');
        // $('.swal2-warning').html(swalIconWarning);
        $('.swal2-confirm').css('box-shadow','4px 5px 15px -5px orange');
        $('.swal2-cancel').css('box-shadow','4px 5px 15px -5px gray');
      }, //END OF ONOPEN: FUNCTION()
      onClose: function(){
        //ENABLE THE PRIMARY BUTTON WHEN THIS SWAL CLOSE
        $('[data-effect="primary_button"]').removeAttr('disabled');
      }
    }); //END OF SWAL
  }else if(type == 'question'){
    Swal.fire({
      title: title,
      text: text,
      type: type,
      showCancelButton: true,
      confirmButtonColor: '#ffc107',
      cancelButtonText: 'No',
      cancelButtonColor: '#f8f9fa',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      onOpen: function(){
        $('.swal2-question').addClass('faa-pulse animated swal-icon-question');
        // $('.swal2-question').addClass('swal-icon-warning');
        // $('.swal2-question').html(swalIconQuestion);
        $('.swal2-confirm').css('box-shadow','4px 5px 15px -5px orange');
        $('.swal2-cancel').css('box-shadow','4px 5px 15px -5px gray');
      } //END OF ONOPEN: FUNCTION()
    }).then((result) => {
      if (result.value) {
        $('#modal').modal('hide');
      }
    }); //END OF SWALL .THEN
  }else{

    Swal.fire({ //CALL SWEETMODAL
      title: title,
      html: text,
      type: type,
      showConfirmButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      onOpen: function(){
        $('.swal2-error').addClass('faa-pulse animated swal-icon-danger');
        // $('.swal2-error').addClass('swal-icon-danger');
        // $('.swal2-error').html(swalIconError);
        $('.swal2-confirm').css('box-shadow','4px 5px 15px -5px red');
        $('.swal2-cancel').css('box-shadow','4px 5px 15px -5px gray');
        $('.swal2-confirm').css('box-shadow',' 4px 5px 15px -5px red!important');
      } //END OF ONOPEN: FUNCTION()
    }); //END OF SWAL
  } //END OF ELSE
} //END OF SWAL
