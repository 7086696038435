<template>
  <div class="form-group">
    <label :for="label">{{ this.label }}</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <button type="button" class="btn btn-dark" @click="uploadFile()" style="width: 40px!important; border-top-right-radius : 0px!important; border-bottom-right-radius: 0px!important">
          <i :id="'button_' + named" class="fas fa-upload fa-inverse faa-vertical animated"></i>
        </button>
        <input type="file" class="d-none" v-validate="validate" feedback="feedback" :name="named" @change="setNewLocale($event)" accept=".zip"/>
      </div>
      <input :ref="'input_'+named" type="text" class="form-control border-default" v-model="value" readonly>
      <div class="input-group-append">
        <span class="input-group-text bg-default" data-toggle="popover" data-container="body" data-placement="bottom" :data-content="$t(tooltiped)">
          <i :class="icon + ' fa-inverse'"></i>
        </span>
      </div>
    </div>

    <small v-if="small != 'false'"></small>
  </div>
</template>
<script>

import { toUpperCamelCase, isEmpty } from "../../mixins/Utils";
import { validateElement } from "../../mixins/Validation";

export default {
  data() {
    return {

    };
  },
  props: [
    "label",
    "type",
    "name",
    "tooltip",
    "prefix",
    "suffix",
    "feedback",
    "validate",
    "icon",
    "small"
  ],
  computed: {
    named: function() {
      return this.name ? this.name : "input";
    },
    tooltiped: function() {
      return this.tooltip ? `tooltip.${this.tooltip}` : "tooltip.name";
    },
    value: {
      get(){
        return this.$store.state.locale.name;
      },
      set(value){
        this.$store.commit(this.prefix + "Name", value);
      }

    }
  },
  methods: {
    uploadFile(){
      //GRAB THE SVG TO REMOVE THE ANIMATION WHEN A FILE IS SELECTED**THE ANIMATION CANT BE REMOVED... BECAUSE EVERYTHING BINDS IN ITS ID**
      let svg = document.getElementById('button_'+this.name);

      //STOP THE ANIMATION IF A FILE WAS BEEN SELECTED
      let file = svg.closest('.input-group').querySelector('[type="file"]');
      file.click();
    },
    setNewLocale(event) {
      if(isEmpty(event.target.files[0])){
        this.$store.commit(this.prefix + toUpperCamelCase(this.suffix), null);

        //SET THE NAME OF THE FILE INTO THE INPUT TO BE MORE INTUITIVE TO THE USER
        let input = this.$refs['input_'+this.named];
        this.$store.commit(this.prefix + 'Name', '');



        //SET ITS FEEDBACK BASED ON THE HIDDEN INPUT FILE
        validateElement(document.querySelector(`[name="${this.named}"]`));
      }else{
        //SET THE FILE IN THE VUEX VARIABLE

      this.$store.commit(this.prefix + toUpperCamelCase(this.suffix), event.target.files[0]);
      //SET THE NAME OF THE FILE INTO THE INPUT TO BE MORE INTUITIVE TO THE USER
      let input = this.$refs['input_'+this.named];
      this.$store.commit(this.prefix + 'Name', event.target.files[0].name);

      //SET ITS FEEDBACK BASED ON THE HIDDEN INPUT FILE
      validateElement(document.querySelector(`[name="${this.named}"]`));
      }
    }
  },
  watch: {
    //THIS WATCH CONTROLS IF THE BUTTON UPLOAD HAS OR NOT ANIMATION
    value: function(newValue, oldValue){
      let svg = document.getElementById('button_'+this.name);

      if(!isEmpty(newValue)){
        svg.classList.remove('animated', 'faa-vertical');
      }else{
        svg.classList.add('animated', 'faa-vertical');
      }
    }
  },
  mounted() {
    //WORAROUND TO AVOID THE PROBLEM OF VEE-VALIDATION DO NOT CREATE THE ATTR ARIA-REQUIRED BY PROPS
    setTimeout(
      () => {
        if (this.validate == "required") {
          document
            .getElementsByName(this.named)[0]
            .setAttribute("aria-invalid", true);
          document
            .getElementsByName(this.named)[0]
            .setAttribute("aria-required", true);
        }
      }, //END OF ARROW FUNCTION
      300
    );
  } //END OF MOUNTED
}; //END OF EXPORT DEFAULT
</script>
