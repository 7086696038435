<template>
  <v-select :name="named" v-feedback="feedback" @input="$emit('changed', value), focusNextWiz()" :class="`w-100 ${(validate == 'required')? 'tabled_warning' : ''}`" :aria-required="(validate == 'required') ? true: false" :aria-invalid="(validate == 'required') ? true: false"
    data-table="true" v-model="value" :options="optionsTraited" @search="onSearch" :filterable="false">
    <template slot="no-options">
          {{ $t('label.loading_data') }}
</template>

<template slot="option" slot-scope="option">
  <div class="d-center">
    {{ option.label }}
  </div>
</template>

<template slot="selected-option" slot-scope="option">
  <div class="selected d-center">
    {{ option.label }}
  </div>
</template>
  </v-select>
</template>

<script>
  import {
    toUpperCamelCase,
    isEmpty
  } from '../../mixins/Utils';

  export default {
    created() {
      //POPULATE THE VUE-SELECT WITH ALL DATA GOT FROM THE SERVER WITHOUT ANY FILTER
      this.options = this.onSearch('', false);
    },
    data: () => ({
      invalid: 'false',
      options: [],
      value: null
    }),
    props: [
      'name',
      'route',
      'validate',
      'feedback',
      'val'
    ],
    methods: {
      focusNextWiz() {
        setTimeout(() => {
          //TO AVOID AN EXCEPTION THE QUERY SELECTOR HAS BEEN SHATTERED
          //THIS FRAGMENT GET THE ELEMENT IF IT EXISTS OF COURSE
          let aux = document.querySelector('#nav-wiz .active');
          //THEN IF IT EXISTS, IT MEANS THAT THE MODAL HAS THE NAVCOMPONENT
          if(!isEmpty(aux)){
            //SO GET WHICH CONTAINER IS ACTIVE
            aux = aux.getAttribute('href');
            //FINALLY SEARCH FOR ANY ELEMENT WITH INVALID VALIDATION
            let fields = document.querySelector(`${aux} [aria-invalid="true"]`);
            //IF NONE WAS BEEN FOUND, FOCUS THE WIZNEXT BUTTON
            if (isEmpty(fields)) {
              //IF THE BUTTON EXISTS
              let button = document.getElementById('wizNext');
              if (button != null) {
                //FOCUS IT
                button.focus();
              }
            } //END OF  if (isEmpty(fields))
          } //END OF if(!isEmpty(aux))
        }, 400); // END OF SETTIMEOUT
      }, //END OF METHOD FOCUSNEXTWIZ
      //METHOD USED BY VUE-SELECT AJAX TO SEARCH FOR WHAT WAS INPUTED
      onSearch(search, loading) {
        //VERIFY IF LOADING IS TRUE, IF IT IS NOT, DO NOT SHOW LOADING.
        //THIS CONDITIONAL WAS NECESSARY TO AVOID AND ERROR ON POPULATE THE COMPONENT BY THE CREATED LIFE CYCLE HOOK
        if (loading) {
          //SHOW LOADING
          loading(true);
        }
        //CALL THE SEARCH METHOD BELOW
        this.search(loading, search, this);
      },
      //GET THE JSON RESPONSE FROM THE SERVER
      search: _.debounce((loading, search, vm) => {
        axios.get(`${vm.route}`).then(
          function(response) {
            //POPULATE THE COMPONENT BY PASSING THE AJAX RESPONSE TO THE OPTIONS PROPERTY BIND
            vm.options = response.data;
            //VERIFY IF LOADING IS TRUE, IF IT IS NOT, DO NOT SHOW LOADING.
            //THIS CONDITIONAL WAS NECESSARY TO AVOID AND ERROR ON POPULATE THE COMPONENT BY THE CREATED LIFE CYCLE HOOK
            if (loading) {
              loading(false);
            }
          } //END OF FUNCTION
        ) //END OF THEN
      }, 350) //END OF DEBOUNCE
    }, //END OF METHODS
    computed: {
      //TRAIT THE ARRAY CAME FROM BACK-END
      optionsTraited: function(e) {
        //REMOVE THE OPTION WHICH ARE ALREADY SELECTED
        return this.options;
      }, //END OF OPTIONSTRAITED
      named() {
        return this.name;
      },
      tooltiped() {
        return (this.tooltip) ? trans.$t(this.tooltip) : $('tooltip.generic_select');
      }
    }, //END OF COMPUTED
    mounted() {
      //IF A START VALUE HAS BEEN SET, ATTRIBUTE THE VALUE OF VAL TO THE VALUE V-MODEL
      if (this.val) {
        this.value = this.val;
      }
    } //END OF MOUNTED
  } //END OF EXPORT DEFAULT
</script>

<style lang="scss" scoped>

</style>
