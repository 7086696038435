<template>
  <div>
    <form :action="route" method="post" id="form" @submit.prevent="makeLogin">
      <input type="hidden" name="_token" :value="csrf">

      <div class="row">
        <div class="col-md-12">
          <auth-username @changed="username=$event"></auth-username>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <auth-password @changed="password=$event"></auth-password>
        </div>
      </div>

      <div class="row">
        <div class="icheck-primary col-md-12" style="margin-bottom: 20px!important;">
          <input class="icheck-primary" type="checkbox" checked name="remember"  v-model="remember" id="remember"/>
          <label for="remember" >{{$t('app.login.remember_me')}}</label>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-outline-primary btn-block margin_bottom" id="login" data-effect="primary_button">{{$t('buttons.login') }}</button>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-outline-dark btn-block" @click="goToForgot()">{{ $t('buttons.forgot_password') }}</button>
      </div>
    </div>

  </div>
</template>
<script>

import { setTableEnabled } from '../../mixins/DataTables';

export default {
  data: () => ({
    username: '',
    password: '',
    remember: true,
  }),
  props: [
    'route',
    'csrf',
    'forgot'
  ],
    mounted() {
        setTableEnabled(0);
    },
  methods: {
    makeLogin(event) {
      //VALIDATE ALL FIELDS
      // this.$validator.validate('username', this.name);
      // this.$validator.validate('password', this.host);

      $('#form').submit();
    }, //END OF MAKELOGIN
    goToForgot() {
        location.href = this.$props.forgot;
    }
  } //END OF METHODS
} //END OF EXPORT DEFAULT
</script>
