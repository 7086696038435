<template>
</template>

<script>
import { setTableEnabled } from "../../mixins/DataTables";

export default {
  data: () => ({}),
  mounted() {
    setTableEnabled(0);
  }
}
</script>
