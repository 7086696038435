import {removeClassFeedBackColors} from './Validation';
export default {
  methods: {
    isEmpty(value) {
      if(value == '' || value == 'undefined' || value == null){
        return true;
      }
      return false;
    } //END OF ISEMPTY
  } //END OF METHODS
} //END OF EXPORT DEFAULT

// export const = axios.get('').then();
export function isEmpty(value) {
  //IF USED TO AVOID AN EXCEPTION WHEN VALUE IS NULL
  if(value != null){
    //CODE USED TO AVOID AN UNEXPECTED BEHAVIOR OF THE FUNCTION ISEMPTY RETURN TRUE WHEN THE NUMBER 0 IS ENTRY
    value = value.toString();
  }

  //NOW VERIFY IF THE VALUE IS BLACK, UNDEFINED OR NULL
  if(value == '' || value == 'undefined' || value == null){
    return true;
  }

  return false;
} //END OF ISEMPTY

export const util = {
  methods: {
    initcap(text) {
      let aux = text.split(' ');//text.substring(0, 1).toUpperCase() + text.substring(1);

      text = '';
      aux.map((value)=>{
        text += value.substring(0, 1).toUpperCase() + value.substring(1) + ' ';
      });

      text = text.trim();

      return text;
    },
    humanizeText(text){
      //METHOD WHICH REMOVE "_" AND REPLACE THEM TO BLANK SPACE
      let aux = text.split('_');

      text = '';

      aux.map((value)=>{
        text += value + ' ';
      });

      text = text.trim();

      return text;
    },
    toUpperHumanized(text){
      //METHOD WHICH RETURN THE TEXT HUMANIZED AND INITCAPED
      return this.initcap(this.humanizeText(text));
    }
  }
} //END OF MIXIN UTIL

export function closeBSModal(button_dismiss){
  $('#'+button_dismiss).trigger('click');
}

export function getButtonColor($type){
  switch($type){
    case 'info':
    return '#17a2b8';
    case 'success':
    return '#28a745';
    case 'warning':
    return '#ffc107';
    case 'danger':
    return '#dc3545';
    case 'restore':
    return '#d81b60';
    case 'secondary':
    return '#6c757d';
    case 'light':
    return '#f8f9fa';
    case 'dark':
    return '#343a40';
    case 'error':
    return '#A40000';
    case 'attention':
    return '#ff7701';
    default: //Primary is the default color
    return '#007bff';
  }
} //END OF FUNCTION GETBUTTONCOLOR($TYPE)

export function restartForm(form){
  //REMOVE FROM ALL COMPONENTS THE CLASSES RESPONSABLE TO MAKE FEEDBACK
  let els = document.querySelectorAll('[class$="-success"],[class$="-warning"],[class$="-danger"]');

  for(let i=0; i < els.length; i++){
    els[i].classList.remove('text-success', 'text-warning', 'text-danger', 'border-success', 'border-warning', 'border-danger' , 'bg-success', 'bg-warning', 'bg-danger', 'input-success', 'input-warning', 'input-danger');
  }

  //CLEAR THE TEXT OF ALL SMALLS
  let smalls = document.getElementsByTagName('small');

  for(let i=0; i < smalls.length; i++){
    smalls[i].innerHTML = "";
  }

} //END OF THE FUNCTION RESTARTFORM

//RETURN WHAT KIND OF COMPONENT IT IS
export function getTagVueElement(el){
  if(el.__vue__){
    return el.__vue__.$vnode.componentOptions.tag;
  }else{
    return 'input';
  }
} //END OF THE FUNCTION GETTAGVUEELEMENT()

//FUNCTION WHICH RETURN A STRING TRANSFORMED INTO AN UPPER CAMELCASE
export function toUpperCamelCase(string){

  if ( string == null ) {
    string = '';
  }

  //CREATE A VARIABLE TO KEEP TEMPORARILY EACH PART OF THE STRING
  let temp = string.split("_");

  //CLEAR THE STRING TO BE POSSIBLE CONCATENATE THE JOIN BELOW
  string = '';

  //FOR EACH FRAGMENT OF WORD RESULTED FROM THE SPLIT "_" MAKE A NEW STRING STARTING BY UPPERCASE AND AFTER THAT CAMELCASE
  for(let i= 0; i < temp.length; i++){
    string += temp[i].substring(0, 1).toUpperCase() + temp[i].substring(1);
  }

  temp = string.split('.');

  string = '';

  //FOR EACH FRAGMENT OF WORD RESULTED FROM THE SPLIT "_" MAKE A NEW STRING STARTING BY UPPERCASE AND AFTER THAT CAMELCASE
  for(let i= 0; i < temp.length; i++){
    string += temp[i].substring(0, 1).toUpperCase() + temp[i].substring(1);
  }

  return string;
} //END OF FUNCTION TOCAMELCASE

export function toLowerCamelCase(string){
  //TO MAKE MAINTENANCE EASIER USE THE SAME CODE OF THE FUNCTION BELOW
  let aux = toUpperCamelCase(string);

  //NOW TRANSFORM JUST THE FIRST CARACTER INTO A LOWERCASE
  string = aux.substring(0,1).toLowerCase() + aux.substring(1);

  let temp = string;
  // string = '';

  // //FOR EACH FRAGMENT OF WORD RESULTED FROM THE SPLIT "_" MAKE A NEW STRING STARTING BY UPPERCASE AND AFTER THAT CAMELCASE
  // for(let i= 0; i < temp.length; i++){
  //   string += temp[i].substring(0, 1).toUpperCase() + temp[i].substring(1);
  // }
  return string;
} //END OF FUNCTION TOCAMELCASE

export function initializeResposiveTablePopover(){
  //FORCE THE INITIALIZATION OF ALL POPOVERS CREATED
  setTimeout(() => $('.table-responsive [data-toggle="popover"]').popover(), 500);
} //END OF EXPORT FUNCTION INITIALIZERESPOSIVETABLEPOPOVER()

