
export {randomPassword}

function randomPassword(length, upper, special) {
    var chars = "";

    var lowercase = "abcdefghijklmnopqrstuvwxyz";
    var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var punctuation = "!@#$%^&*()_+~`|}{[]\:;?><,./-=";
    var numeric = "1234567890";

    chars += lowercase;
    chars += numeric;

    if (upper) {
      chars += uppercase;
    }

    if (special) {
      chars += punctuation;
    }

    var password = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      password += chars.charAt(i);
    }
    return password;
  }
