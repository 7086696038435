<template>
    <div>
        <div class="image">
            <avatar :fullname="fullname" :size="32"></avatar>
        </div>
        <div class="info">
            <a href="#" @click="showProfile()" class="d-block" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" >
                {{ fullname }}
            </a>
        </div>
    </div>
</template>

<script>
import { showSwal } from '../../mixins/Swal';
import { toUpperCamelCase, toLowerCamelCase, isEmpty, closeBSModal } from '../../mixins/Utils';
import { validateForm } from '../../mixins/Validation';

export default {
    props: [
        'fullname',
        'user'
    ],
    methods: {
        showProfile() {
            let object = {
                id: this.user,
                operation: 'read_profile'
            }
            axios.post(this.$routes.route('profile.get'), object).then((response) => {
                if(response.data.status == 'success'){
                    let keys = Object.keys(response.data.data);

                    keys.map( (key) => {
                        //THESES FIELDS BELOW WILL NEVER BE PROCESSED BY THE EDIT DATATABLES BECAUSE THERE ARE NOT RELEVANT AND AVOID AN EXCEPTION
                        if(key != 'created_at' && key != 'updated_at' && key != 'deleted_at'){
                            store.commit('profile' + toUpperCamelCase(key), response.data.data[key] );
                        }

                        // SHOW THE MODAL
                        $('#modal_profile').modal();
                    }); //END OF KEYS.MAP
                }else if(response.data.status == 'warning'){
                    showSwal('warning', response.data.title, response.data.text);
                }else{
                    showSwal('error', response.data.title, response.data.text);
                }
            });

        //    this.$store.hotUpdate(profile);
        },
    }
}
</script>
